<template>
  <div class="course-container">
    <!-- 标题 -->
    <div class="course-title">
      <h2>更多套餐</h2>
      <a class="turnList" @click="openDialog">
        全部
        <div class="button">
          <svg-icon icon-class="right" class-name="right-icon" />
        </div>
      </a>
    </div>
    <!-- 列表 -->
    <div class="course-list">
      <div class="course-item" v-for="(item,index) in dataList" :key="index" @click="openDialog" ref="courseItem" :style="{ height: `${courseItemHeight}px` }">
        <div class="course-item-bg"></div>
        <div class="course-item-info">
          <div class="course-item-info-title" :style="{ height: `${courseItemTitleHeight}px` }">
            <span>{{item.title}}</span>
          </div>
          <div class="course-item-info-cover" :style="{ height: `${courseItemImgHeight}px` }">
            <div :style="{ 'background-image': `url(${item.image[0]})` }"></div>
            <div :style="'background-image: url('+ item.image[1] +')'">
              <div></div>
            </div>
            <div :style="'background-image: url('+ item.image[2] +')'">
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {

  },
  data() {
    return {
      dataList:[
        {title:'安全驾驶',image:[require('@assets/images/more/more-3.png'),require('@assets/images/more/more-1.png'),require('@assets/images/more/more-2.png')]},
        {title:'亲子安全',image:[require('@assets/images/more/more-4.png'),require('@assets/images/more/more-5.png'),require('@assets/images/more/more-6.png')]},
        {title:'医疗急救',image:[require('@assets/images/more/more-7.png'),require('@assets/images/more/more-8.png'),require('@assets/images/more/more-9.png')]},
      ],
      courseItemHeight: 0,
      courseItemTitleHeight: 0,
      courseItemImgHeight: 0
    };
  },
  mounted() {
    this.getCourseItemImgHeight()
    window.addEventListener('resize', this.getCourseItemImgHeight, false)
  },
  destroyed() {
    window.removeEventListener('resize', this.getCourseItemImgHeight)
  },
  methods:{
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
    getCourseItemImgHeight () {
      this.courseItemTitleHeight = this.$refs.courseItem[0].clientWidth > 186 ? 96 : 86;
      this.courseItemImgHeight = (this.$refs.courseItem[0].clientWidth > 186 ? 186 : this.$refs.courseItem[0].clientWidth) * 103 / 186;
      this.courseItemHeight = this.courseItemImgHeight + (this.$refs.courseItem[0].clientWidth > 186 ? 96 : 86);
    }
  }
};
</script>
<style lang="scss" scoped>
.course-container {
  padding-left: 15px;
  margin-bottom: 30px;
  // 标题
  .course-title {
    display: flex;
    align-items: center;
    height: 84px;
    justify-content: space-between;
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #4a4a4a;
    }
    .turnList {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      .button {
        width: 53px;
        height: 53px;
        background-color: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;
      }
    }
    .other {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .count {
        margin-left: 15px;
        font-size: 64px;
        color: #f8f8f8;
      }
      a {
        display: flex;
        justify-content: flex-end;
        .turnList {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
  // 列表
  .course-list {
    display: flex;
    justify-content: space-between;
    .course-item {
      width: 100%;
      max-width: 286px;
      border-radius: 2px;
      position: relative;
      z-index: 0;
      overflow: hidden;
      cursor: pointer;
      .course-item-bg {
        filter: blur(46px);
      }
      &:nth-of-type(1) {
        background: linear-gradient(360deg, #ffffff 0%, #727272 100%);
        .course-item-bg {
          background: linear-gradient(360deg, #ffffff 0%, #727272 100%);
        }
      }
      &:nth-of-type(2) {
        background: linear-gradient(360deg, #d9dfe3 0%, #96a6ba 100%);
        .course-item-bg {
          background: linear-gradient(360deg, #d9dfe3 0%, #96a6ba 100%);
        }
      }
      &:nth-of-type(3) {
        background: linear-gradient(180deg, #4e528b 0%, #cbc3d2 100%);
        .course-item-bg {
          background: linear-gradient(180deg, #4e528b 0%, #cbc3d2 100%);
        }
      }
      &>div {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
      }
      &-info {
        z-index: 2;
        &-title {
          width: 100%;
          padding: 30px 0 0 0;
          z-index: 5;
          display: flex;
          justify-content: center;
          span {
            font-size: 18px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            text-shadow: 0px 0px 30px rgba(59, 59, 59, 0.5);
            letter-spacing: 2px;
          }
        }
        &-cover {
          position: relative;
          & > div {
            position: absolute;
            background-size: 100% 100%;
            &:nth-of-type(1) {
              width: 100%;
              max-width: 186px;
              height: 100%;
              background-color: greenyellow;
              z-index: 3;
              left: 50%;
              transform: translateX(-50%);
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              width: 142px;
              max-height: 80px;
              height: 90%;
              z-index: 2;
              bottom: 1px;
              &>div{
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.3);
              }
            }
            &:nth-of-type(2) {
              background-color: green;
              left: 26px;
            }
            &:nth-of-type(3) {
              background-color: blue;
              right: 26px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1167px) {
  .course-container {
    .course-list {
      .course-item {
        border-radius: 0;
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .course-container {
    padding: 0 10px;
    .course-title {
      .turnList {
        .button {
          width: 8vw;
          height: 8vw;
          max-width: 53px;
          max-height: 53px;
        }
      }
    }
    .course-list {
      .course-item {
        .course-item-bg {
          filter: blur(18vw);
        }
      }
    }
  }
}
</style>
