<template>
  <div>
    <footer class="horizontal-footer">
      <div class="wrapper">
        <a class="logo" href="javascript:void(0)" @click="turnIndex()">
          <img src="@assets/images/logo.png" alt="logo" />
        </a>
        <div class="info">
          <div class="left">
            <div class="other">
              <a @click="openDialog('about')">关于我们</a>
              <a @click="openDialog('download')">客户端下载</a>
            </div>
            <div class="record">主体备案号 : 京ICP备2024091705号</div>
          </div>
          <div class="right">
            <div class="tip">
              微信扫一扫
              <br />更多惊喜等着你
            </div>
            <div class="qrcode">
              <img src="@assets/images/scan-qcrode.png" alt />
            </div>
          </div>
        </div>
      </div>
    </footer>
    <footer class="vertical-footer">
      <div class="qrcode">
        <img class="" src="@assets/images/mobile/qrcode.png" />
        <p>微信扫一扫</p>
        <p>更多精彩等着你</p>
      </div>
      <!--<div class="footLogo">
        <img src="@assets/images/mobile/logoBlue.png" alt="" />
      </div>-->
      <div class="footInfo">
        <p>
          <a href="http://beian.miit.gov.cn">北京睿识课科技有限公司 <br />「京ICP备2024091705号」</a>
        </p>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  components: {

  },
  methods: {
    openDialog(data) {
      if (data === "download") {
        this.$root.$emit('openDialog', { type: 'dialog' })
      }else{
        this.$router.push({
          name: data
        }).catch(err=>err)
      }
    },
    turnIndex(){
      this.$router.push({
        name: 'discovery'
      }).catch(err=>err)
    }
  },
};
</script>
<style lang="scss" scoped>
.horizontal-footer {
  width: 100%;
  height: 124px;
  background-color: #fff;
  border-top: 1px solid rgba(174, 174, 174, 1);
  .wrapper {
    margin: 0 auto;
    height: 124px;
    max-width: 1230px;
    display: flex;
    align-items: center;
    overflow: hidden;
    .logo {
      margin-right: 20px;
      img {
        width: 85px;
        height: 40px;
      }
    }
    .info {
      display: flex;
      justify-content: space-between;
      flex: 1;
      .left {
        display: flex;
        flex-direction: column;
        height: 124px;
        .other {
          margin: 33px 0 20px;
          a {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: rgba(74, 74, 74, 1);
            line-height: 22px;
            margin-right: 20px;
          }
        }
        .record {
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(74, 74, 74, 1);
          line-height: 20px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        .tip {
          text-align: right;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(74, 74, 74, 1);
          line-height: 20px;
          margin-right: 20px;
        }
        .qrcode{
          > img {
            width: 88px;
            height: 88px;
          }
        }
      }
    }
  }
}
.vertical-footer {
  background: #f9f9f9;
  padding: 54px 0;
  width: 100%;
  text-align: center;
  display: none;
  .footLogo img {
    width: 204px;
  }
  .footInfo p {
    margin-top: 20px;
    font-size: 14px;
    line-height: 20px;
    color: #444;
  }
  .qrcode {
    font-size: 18px;
    color: #444;
    line-height: 26px;
    text-align: center;
  }
  .qrcode p {
    margin: 0;
  }
  .qrcode p:nth-child(2) {
    margin-top: 10px;
  }
  .qrcode img {
    width: 110px;
    height: 110px;
  }
}
@media only screen and (max-width: 1230px) {
  .horizontal-footer {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 576px) {
  .horizontal-footer {
    display: none;
  }
  .vertical-footer {
    display: block;
  }
}
</style>
