<template>
  <div class="daily-recom">
    <div v-for="(item, index) in navList" :key="index" @click="openDialog">
      <div>
        <svg-icon :icon-class="item.icon" class-name="daily-icon" />
      </div>
      <div class="daily-name">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      navList: [
        { name: "每日20课", icon: "fxmrkc" },
        { name: "优选套餐", icon: "fxyxtc" },
        { name: "热门课程", icon: "fxrmkc" },
        { name: "猜你喜欢", icon: "fxcnxh" }
      ],
    };
  },
  components: {

  },
  methods: {
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
  }
};
</script>
<style lang="scss" scoped>
.daily-recom {
  height: 152px;
  width: 100%;
  display: flex;
  padding: 0 20px;
  > div {
    padding-top: 40px;
    flex: 1;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      .daily-icon {
        width: 48px;
        height: 48px;
      }
    }
    .daily-name {
      margin-top: 20px;
      color: #4a4a4a;
    }
  }
}
</style>
