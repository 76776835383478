<template>
  <div class="left-menu-wraper" ref="sidebar">
    <div class="left-menu-content">
      <div class="left-menu-List">
        <a
          v-for="(item,index) in menuList"
          :href="link"
          :key="index"
          :class="item.isActive?'active':''"
           @click="openDialog"
        >{{item.categoryName}}</a>
      </div>
      <div class="left-menu-download">
        <div class="download-info">
          <div class="download-text">
            <h3>扫描二维码下载</h3>
            <div class="download-introduce">
              <p>即刻体验</p>
              <p>我们无与伦比的</p>
              <p>客户端服务</p>
            </div>
          </div>
          <img class="download-qrcode" src="@assets/images/qrcode.png" alt />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { recommendList } from '@api/discovery/index';
export default {
  data() {
    return {
      scroll: '',
      link:'javascript:void(0);',
      firstMenu:{ categoryName: "推荐",categoryId:'', isActive: true },
      menuList: []
    };
  },
  components: {

  },
  mounted() {
    window.addEventListener('scroll', this.menu)
    this.getRecommendList()
  },
  destroyed() {
    window.removeEventListener('scroll', this.menu)
  },
  methods:{
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    menu() {
      if (!this._isMobile()) {
        this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
        if(this.scroll <= 295){
          this.$refs.sidebar.style.position = 'relative'
          this.$refs.sidebar.style.top = '0'
        }else if(this.scroll > 295 && this.scroll < 1589){
          this.$refs.sidebar.style.position = 'fixed'
          this.$refs.sidebar.style.top = '-120px'
        }else if(this.scroll >= 1589){
          this.$refs.sidebar.style.top = '-180px'
        }
      }
    },
    // 获取推荐标题
    getRecommendList () {
      recommendList().then(({ data }) => {
        if (data.data && data.success === true) {
          this.menuList = [this.firstMenu]
          data.data.map((item,index)=>{
            if(index<6){
              this.menuList.push(item)
            }
          })
        } else {
          this.menuList = [this.firstMenu]
        }
      })
    },
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    }
  }
};
</script>
<style lang="scss" scoped>
.left-menu-wraper {
  width:265px;
  position: relative;
  top: 0;
  transition: all 0.8s;
  .left-menu-content {
    display: flex;
    flex-direction: column;
    padding: 30px;
    background: #ffffff;
    .left-menu-List {
      a {
        display: block;
        margin-top: 9px;
        height: 32px;
        line-height: 32px;
        color: #6e6e6e;
        font-size: 14px;
        font-weight: 300;
        text-decoration: none;
        white-space: nowrap;
        transition: color 0.2s;
        :first-child {
          margin-top: 0;
        }
        &:hover{
          color: #40ABFE;
        }
      }
      .active {
        font-weight: bolder;
        border-bottom: 3px solid;
        color: inherit;
        cursor:auto;
        &:hover{
          color: #6e6e6e;
        }
      }
    }
    .left-menu-download {
      position: relative;
      margin-top: 20px;
      .download-info {
        height: 119px;
        .download-text {
          position: relative;
          padding-top: 5px;
          width: 100px;
          h3 {
            font-size: 12px;
            font-weight: 500;
            color: $--color-primary;
            line-height: 16px;
            margin: 0;
          }
          .download-introduce {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            p {
              margin: 0;
              color: #6e6e6e;
            }
          }
        }
        .download-qrcode {
          position: absolute;
          top: 0;
          right: 0;
          width: 100px;
          height: 100px;
        }
      }
    }
  }
}
</style>
