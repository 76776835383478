export default {
  namespaced: true,
  state: {
    searchName: '',
    courseId:'',
    comboId:''
  },
  mutations: {
    updateSearchName(state, name) {
      state.searchName = name
    },
    updateCourseId(state, name) {
      state.courseId = name
    },
    updateComboId(state, name) {
      state.comboId = name
    }
  }
}
