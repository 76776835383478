<template>
    <div class="download-mark" v-if="isShow">
      <div class="popup-download">
        <a-row type="flex" justity="center">
          <a-col :xs="10">
            <div class="info">
              <span @click="closeHandle">
                <svg-icon icon-class="close-X" class-name="close-icon" />
              </span>
              <img src="@assets/images/logo_big.png" alt="logo" />
              <h2>睿识课教育</h2>
              <h4>与安全同行</h4>
            </div>
          </a-col>
          <a-col :xs="14">
            <div class="qrcode">
              <div class="qrcode-img">
                <img src="@assets/images/qrcode.png" alt="logo" />
              </div>
              <div class="text">扫描下载“睿识课”安全科普平台 更多精彩等着你</div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
</template>
<script>
export default {
  name:'download',
  data() {
    return {
      isShow: false,
    };
  },
  methods: {
    init() {
      this.isShow = true;
    },
    closeHandle() {
      this.isShow = false;
    },
  },
};
</script>
<style lang="scss">
.download-mark {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 999;
  .popup-download {
    width: 100%;
    max-width: 600px;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    overflow: hidden;
    .info {
      width: 100%;
      height: 100%;
      background-color: #4a9afa;
      position: relative;
      text-align: center;
      .close-icon {
        position: absolute;
        width: 15px;
        height: 15px;
        left: 20px;
        top: 20px;
        cursor: pointer;
      }
      img {
        width: 60px;
        height: 60px;
        background-color: #ffffff;
        margin-top: 168px;
      }
      h2 {
        margin: 14px 0 10px;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }
      h4 {
        margin: 0;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        text-align: center;
      }
    }
    .qrcode {
      width: 100%;
      max-width: 298px;
      margin: 0 auto;
      .qrcode-img {
        width: 100%;
        max-width: 298px;
        height: 242px;
        background: url(~@assets/images/download/tc_bg.png);
        margin-top: 58px;
        position: relative;
        img {
          position: absolute;
          width: 120px;
          height: 120px;
          left: 95px;
          top: 81px;
        }
      }

      .text {
        width: 228px;
        margin: 30px auto 90px auto;
        text-align: center;
        color: #4a4a4a;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        line-height: 22px;
      }
    }
  }
}

// .fade-enter {
//   opacity: 0;
// }
// .fade-enter-active {
//   transition: opacity 3s;
// }
// .fade-leave-to {
//   opacity: 0;
// }
// .fade-leave-active {
//   transition: opacity 3s;
// }
</style>
