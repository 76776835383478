<template>
  <div class="discovery-body">
    <div class="left-menu">
      <left-menu></left-menu>
    </div>
    <div class="right-body">
      <right-body></right-body>
    </div>
    <back-top></back-top>
  </div>
</template>
<script>
import backTop from '../../components/backTop'
import LeftMenu from './base/left-menu'
import RightBody from './base/right-body'
export default {
  metaInfo: {
    title: `发现 - 睿识课`,
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课是一个专注于安全科普类平台，为用户提供场景化、实用性强的1000余堂安全科普课程，以预防在不同场所、不同环境下发生灾害。平台聚集着一批热爱汽车和驾驶、关注健康与安全、懂生活高素质的，具有影响力和购买力的用户。",
      },
    ],
  },
  data() {
    return {
      scroll: ''
    }
  },
  components: {
    backTop,
    LeftMenu,
    RightBody
  },
  mounted() {

  }
}
</script>
<style lang="scss" scoped>
.discovery-body{
  margin: 0 auto;
  width: 100%;
  max-width: 1240px;
  .left-menu{
    width: 265px;
    position: absolute;
    top: 90px;
    z-index: 2;
  }
  .right-body{
    margin-left: 280px;
  }
}
@media only screen and (max-width: 920px) {
  .discovery-body {
    .left-menu{
      display: none;
    }
    .right-body {
      margin-left: 0;
    }
  }
}
</style>
