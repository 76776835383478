import {
  httpGet
} from '@/api'
let prefixHome = '/homePage/' //banner
let prefixRecom = '/course/' //热门推荐
let prefixOptim = '/combo/' //优选套餐
export const bannerList = (params) => httpGet(prefixHome + 'bannerList', params) // banner列表
export const recommendList = (params) => httpGet(prefixHome + 'courseCategoryTopList', params) // 推荐列表
export const hotSearchList = (params) => httpGet(prefixHome + 'hotSearchList', params) // 搜索词语
export const aiRecommendList = (params) => httpGet(prefixRecom + 'aiRecommendList', params) // AI推荐列表
export const hotRecommendList = (params) => httpGet(prefixRecom + 'hotRecommendList', params) // 热门课程列表
// export const optimizationList = (params) => httpGet(prefixOptim + 'handpickList', params) // 优选套餐列表
export const optimizationList = (params) => httpGet(prefixOptim + 'recommendedCombos', params) // 优选套餐列表
export const recommendInfoList = (params) => httpGet(prefixRecom + 'listPage', params) // 新课列表
