<template>
  <div class="container">
    <banner></banner>
    <daily-recom></daily-recom>
    <recom-course></recom-course>
    <pref-set-meal></pref-set-meal>
    <course-list></course-list>
    <more-set-meal></more-set-meal>
  </div>
</template>
<script>
import banner from "./banner";
import dailyRecom from "./daily-recom";
import recomCourse from "./recom-course";
import prefSetMeal from "./pref-set-meal";
import courseList from "./course-list";
import moreSetMeal from "./more-set-meal";
export default {
  components: {
    banner,
    dailyRecom,
    recomCourse,
    prefSetMeal,
    courseList,
    moreSetMeal
  }
};
</script>
<style lang="scss" scoped>
.container {
  padding-top: 298px;
}
@media only screen and (max-width: 920px) {
  .container {
    padding-top: 32%;
  }
}
</style>
