<template>
  <div class="course-container">
    <!-- 标题 -->
    <div class="course-title">
      <h2>推荐课程</h2>
      <div class="other">
        <div class="count">10万+</div>
        <a>
          <div class="turnList" @click="openDialog">
            全部
            <div class="button">
              <svg-icon icon-class="right" class-name="right-icon" />
            </div>
          </div>
        </a>
      </div>
    </div>
    <!-- 列表 -->
    <div class="course-list">
      <vue-scroll :ops="vueScrollOps">
        <div class="course-list-inner">
          <!-- 首图 -->
          <div class="line-one">
            <div class="recommend">
              <div class="wrapper">
                <img class="cover" :src="filterNull(firstItem.picUrlList)[0]" alt="">
                <div class="count">
                  <div class="triangle"></div>
                  {{ firstItem.watchCount }}
                </div>
                <div class="info">
                  <div class="avatar" :style="'background-image: url(' + firstItem.offerPicUrl+ ')'">
                  </div>
                  <div class="info-wrapper">
                    <div class="name">{{firstItem.courseName}}</div>
                    <div class="text">{{firstItem.offerUserName}}</div>
                  </div>
                  <div class="player" @click="turnPlayer(firstItem)">
                    <div class="player-triangle"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="collect">
              <div
                class="course-item"
                v-for="(item, index) in dataListFirst"
                :key="index"
              >
                <div class="cover" @click="turnPlayer(item)">
                  <div class="img">
                    <img :src="filterNull(item.picUrlList)[0]" alt />
                    <!-- <div
                      style="width: 150px; height: 84px; background-color: green"
                    ></div> -->
                  </div>
                  <div class="count">
                    <div class="triangle"></div>
                    {{ item.watchCount }}
                  </div>
                  <div class="action">
                    <div class="player">
                      <div class="player-triangle"></div>
                    </div>
                  </div>
                </div>
                <div class="info introduction">{{ item.courseName }}</div>
                <div class="info name">{{ item.offerUserName }}</div>
                <div class="nav-tag" v-if="item.labelName">
                  <div class="tags">
                    <span
                      class="tag-item"
                      v-for="(it, ind) in item.labelName.split(',')"
                      :key="ind"
                    >
                      <a>#{{ it }}</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="line-two">
            <div
              class="course-item"
              v-for="(item, index) in dataListSecond"
              :key="index"
            >
              <div class="cover" @click="turnPlayer(item)">
                <div class="img">
                  <img :src="filterNull(item.picUrlList)[0]" alt />
                  <!-- <div
                    style="width: 150px; height: 84px; background-color: green"
                  ></div> -->
                </div>
                <div class="count">
                  <div class="triangle"></div>
                  {{ item.watchCount }}
                </div>
                <div class="action">
                  <div class="player">
                    <div class="player-triangle"></div>
                  </div>
                </div>
              </div>
              <div class="info introduction">{{ item.courseName }}</div>
              <div class="info name">{{ item.offerUserName }}</div>
              <div class="nav-tag" v-if="item.labelName">
                <div class="tags">
                  <span
                    class="tag-item"
                    v-for="(it, ind) in item.labelName.split(',')"
                    :key="ind"
                  >
                    <a>#{{ it }}</a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import { aiRecommendList, hotRecommendList } from "@api/discovery/index";
export default {
  data() {
    return {
      dataList: [],
      firstItem: { picUrlList: [] },
      dataListFirst: [],
      dataListSecond: [],
      vueScrollOps: {
        bar: {
          background: "rgba(0, 0, 0, 0.3)",
          size: this.$getBrowser().versions.mobile ? '0px' : '8px'
        }
      }
    };
  },
  components: {

  },
  computed: {
  },
  created() {
    this.getHotRecommendList();
  },
  methods: {
    // 获取热门课程
    getHotRecommendList() {
      hotRecommendList().then(({ data }) => {
        if (data.data && data.success === true) {
          this.dataList = data.data.rows;
        } else {
          this.dataList = [];
        }
        // 获取AI推荐
        return aiRecommendList().then(({ data }) => {
          if (data.data && data.success === true) {
            this.dataList.concat(data.data.rows);
          }
          if (this.dataList && this.dataList.length >= 1) {
            this.firstItem = this.dataList[0];
            this.dataList.forEach((ele, index) => {
              if (index > 0 && index < 4) {
                this.dataListFirst.push(ele);
              } else if (index > 3 && index < 9) {
                this.dataListSecond.push(ele);
              }
            });
          }
        });
      });
    },
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
    turnPlayer(data){
      window.sessionStorage.setItem('Play','course')
      window.sessionStorage.setItem('comboId','')
      window.sessionStorage.setItem('courseId',data.courseId)
      this.$router.push({
        name: 'player'
      }).catch(err=>err)
    }
  },
};
</script>
<style lang="scss" scoped>
.course-container {
  padding-left: 15px;
  // 标题
  .course-title {
    display: flex;
    align-items: center;
    height: 84px;
    flex: 2;
    align-items: center;
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #4a4a4a;
    }
    .other {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .count {
        margin-left: 15px;
        font-size: 64px;
        color: #f8f8f8;
      }
      a {
        display: flex;
        justify-content: flex-end;
        .turnList {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
          .button {
            width: 53px;
            height: 53px;
            background-color: #f2f2f2;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 14px;
          }
        }
      }
    }
  }
  // 列表
  .course-list {
    height: 437px;
    margin-bottom: 20px;
    &:hover {
      /deep/ .__bar-is-horizontal {
        opacity: 1!important;
      }
    }
    /deep/ .__panel {
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .course-list-inner {
      min-width: 877px;
    }
    .line-one {
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      .recommend {
        width: 350px;
        height: 196px;
        padding-right: 10px;
        box-sizing: content-box;
        flex-shrink: 0;
        .wrapper {
          width: 100%;
          height: 100%;
          position: relative;
          .cover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            max-height: 196px;
            border-radius: 2px;
            z-index: 0;
          }
          .info{
            width: 100%;
            height: 60px;
            background: linear-gradient(360deg, #000000 0%, rgba(0, 0, 0, 0.6) 100%);
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 14px;
            &>div{
              float: left;
            }
            .avatar{
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background-color: #4a4a4a;
              background-size: 100% 100%;
            }
            .info-wrapper{
              padding: 0 14px;
              .name{
                width: 200px;
                font-size: 14px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 14px;
                margin-bottom: 6px;
                overflow: hidden;
                white-space:nowrap;
                text-overflow:ellipsis;
              }
              .text{
                font-size: 12px;
                font-family: PingFangSC-Light, PingFang SC;
                font-weight: 300;
                color: #FFFFFF;
                line-height: 12px;
              }
            }
            .player{
              float: right;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: #FFFFFF;
              cursor: pointer;
              display: flex;
              justify-content: center;
              align-items: center;
              .player-triangle{
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-left: 10px solid #000000;
                border-bottom: 6px solid transparent;
                margin-left: 2px;
              }
            }
          }
          .count {
            position: absolute;
            right: 0;
            top: 10px;
            padding-left: 15px;
            padding-right: 6px;
            background: #000;
            border-top-left-radius: 9px;
            border-bottom-left-radius: 9px;
            height: 17px;
            font-size: 12px;
            font-weight: 300;
            color: rgba(255, 255, 255, 1);
            line-height: 17px;
          }
          .triangle {
            position: absolute;
            left: 7px;
            top: 6px;
            width: 0;
            height: 0;
            border-top: 3px solid transparent;
            border-left: 6px solid #ffffff;
            border-bottom: 3px solid transparent;
          }
        }
      }
      .collect {
        flex: 3;
        max-width: 552px;
        display: flex;
        justify-content: space-between;
      }
    }
    .line-two {
      display: flex;
      justify-content: space-between;
      .course-item {
        &:nth-of-type(1) {
          margin-left: 0;
        }
      }
    }
    .course-item {
      position: relative;
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
      }
      .cover {
        position: relative;
        padding-right: 9px;
        cursor: pointer;
        z-index: 0;
        &:before,
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #d9d9d9;
          position: absolute;
          top: 0;
        }
        &:before {
          border: 0.5px solid #d6d6d6;
          right: 4px;
          transform: scale(0.85);
          transform-origin: 100% 50%;
          z-index: 2;
          border-radius: 2px;
        }
        &:after {
          // border: 0.5px solid #c4c4c4;
          border: 0.5px solid #d6d6d6;
          right: 0;
          transform: scale(0.73);
          transform-origin: 100% 50%;
          z-index: 1;
          border-radius: 2px;
          opacity: 0.5;
        }
        .img {
          position: relative;
          z-index: 3;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 150px;
            height: 84px;
          }
        }
        .count {
          z-index: 4;
          position: absolute;
          right: 9px;
          top: 10px;
          padding-left: 15px;
          padding-right: 6px;
          background: #000;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          height: 17px;
          font-size: 12px;
          font-weight: 300;
          color: rgba(255, 255, 255, 1);
          line-height: 17px;
        }
        .triangle {
          position: absolute;
          left: 7px;
          top: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 6px solid #ffffff;
          border-bottom: 3px solid transparent;
        }
        .action{
          width: 100%;
          height: 84px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          display: none;
          justify-content: center;
          align-items: center;
          .player{
            float: right;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            .player-triangle{
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-left: 10px solid #000000;
              border-bottom: 6px solid transparent;
              margin-left: 2px;
            }
          }
        }
        &:hover{
          .action{
            display: flex;
          }
        }
      }
      .info {
        width: 150px;
        margin-top: 14px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
      }
      .introduction {
        height: 42px;
        overflow: hidden;
        display: -webkit-box;
        //  /*! autoprefixer: off */
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        // /* autoprefixer: on */
      }
      .name {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ababab;
        line-height: 12px;
        margin-top: 10px;
      }
      .nav-tag {
        margin-top: 14px;
        width: 150px;
        height: 16px;
        overflow: hidden;
        .tags {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(74, 154, 250, 1);
          .tag-item {
            margin-right: 10px;
            a {
              color: inherit;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .course-container {
    padding: 0 10px;
    .course-title {
      .other {
        a {
          .turnList {
            .button {
              width: 8vw;
              height: 8vw;
              max-width: 53px;
              max-height: 53px;
            }
          }
        }
        .count {
          font-size: 8vw;
        }
      }
    }
  }
}
</style>
