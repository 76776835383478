<template>
  <div class="back-back">
    <div class="back-top" @click="backTop" v-show="isScroll">
      <svg-icon icon-class="back-top" class-name="right-icon" />
    </div>
  </div>
</template>
<script>
export default {
  name: "scrollTop",
  data() {
    return {
      isScroll: false,
      scroll: 0,
    };
  },
  computed: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //返回顶部事件
    backTop() {
      var timer = setInterval(function () {
        let osTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let ispeed = Math.floor(-osTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          osTop + ispeed;
        this.isScroll = true;
        if (osTop === 0) {
          clearInterval(timer);
        }
      }, 30);
    },
    //滑动超过200时显示按钮
    handleScroll() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 200) {
        this.isScroll = true;
      } else {
        this.isScroll = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.back-back {
  width: 100%;
  max-width: 1340px;
  bottom: 0;
  transform: translateY(0);
  z-index: 10;
  position: fixed;
}
.back-top {
  width: 40px;
  height: 40px;
  background-color: #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 100;
  right: 40px;
  bottom: 150px;
  cursor: pointer;
  transition: opacity 0.2s;
}
</style>