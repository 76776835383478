// 全局标题
window.GLOBALTITLE = '睿识课'

// 实现深度拷贝
export const deepClone = (obj) => {
  var newObj
  // 数组类型的拷贝
  if (obj instanceof Array) {
    newObj = []
    var i = obj.length
    while (i--) {
      newObj[i] = deepClone(obj[i])
    }
    return newObj
  } else if (obj instanceof Object) {
    newObj = {}
    for (var k in obj) {
      newObj[k] = deepClone(obj[k])
    }
    return newObj
  } else {
    return obj
  }
}

// 去除null
export function filterNull (_data) {
  if (!_data) return []
  let results = deepClone(_data)
  function trimSpace (array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i] == '' || array[i] == ' ' || array[i] == null || typeof (array[i]) == 'undefined') {
          array.splice(i, 1)
          i = i - 1
        }
      }
    return array
  }
  trimSpace(results)
  return results
}

// 判断是否为手机端
export function getBrowser () {
  return {
    versions: (function () {
      let u = navigator.userAgent;
      return {
        mobile: !!u.match(/AppleWebKit.*Mobile.*/),
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        android: u.indexOf("Android") > -1 || u.indexOf("Linux") > -1,
        iPhone: u.indexOf("iPhone") > -1,
        iPad: u.indexOf("iPad") > -1,
        weixin: u.toLowerCase().indexOf("micromessenger") > -1,
        Safari: u.indexOf("Safari") > -1,
      };
    })(),
    language: (
      navigator.browserLanguage || navigator.language
    ).toLowerCase()
  }
}

/**
 * 格式化广告列表
 * @params {Array} formatArr 格式化的数组
 * @params {Number} length hideIndex存在时为拼接数组下标, 不存在时为返回的数组长度
 * @params {Number} hideIndex 隐藏数组下标
 * */
export function formatAdList (formatArr = [], length = 8, hideIndex) {
  let arr = [...formatArr]
  if (hideIndex !== undefined) {
    const deleteItem = arr.splice(hideIndex, 1)
    arr = arr.concat(deleteItem)
  } else {
    arr = arr.slice(0, length)
  }
  return arr
}
