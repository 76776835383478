<template>
  <div class="course-container">
    <div class="wrapper">
      <div class="nav-bar">
        <h3>新课</h3>
        <div class="nav-tab">
          <vue-scroll :ops="vueScrollOps">
            <div class="nav-tab-inner">
              <div class="nav-tab-item" v-for="(tab,index) in tabList" @click="toggle(index,tab.categoryId)" :key="index">
                <a :class="{active:active==index}">{{tab.categoryName}}</a>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
      <a-row class="course-wrapper" type="flex" justity="center">
        <a-col :xs="24" :md="9">
          <div class="left-img">
            <img class="first-img" :src="imgList[0]" alt="">
            <img class="second-img" :src="imgList[1]" alt="">
            <img class="third-img" :src="imgList[2]" alt="">
          </div>
        </a-col>
        <a-col :xs="24" :md="15">
          <div class="right-list">
            <div class="list-header">
              <div class="player" @click="turnPlayer()">
                <div class="play-btn">
                  <div class="triangle"></div>
                </div>
                <h4>播放全部</h4>
              </div>
              <a class="turn-list" @click="openDialog">
                01/{{total>10?total:('0'+total)}}
                <div class="button">
                  <svg-icon icon-class="right-jiantou" class-name="right-icon" />
                </div>
              </a>
            </div>
            <div class="list-body">
              <div class="list-item" v-for="(item,index) in dataList" :key="index" @click="turnPlayer(item)">
                <div class="index">0{{index+1}}</div>
                <div class="title">{{item.courseName}}</div>
                <div class="auth">{{item.offerUserName}}</div>
                <div class="time">{{item.watchCount}} <img src="@assets/images/watch-white.png" alt="watch" /></div>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
import { recommendList,recommendInfoList } from "@api/discovery/index";
export default {
  data() {
    return {
      active: 0,
      total:1,
      tabList: [],
      imgList: [],
      dataList: [],
      vueScrollOps: {
        bar: {
          background: "rgba(0, 0, 0, 0.3)",
          size: this.$getBrowser().versions.mobile ? '0px' : '5px'
        }
      }
    };
  },
  created(){
    this.getRecommendList()
  },
  components: {

  },
  methods: {
    toggle(i, row) {
      this.active = i;
      this.getRecommendInfoList(row)
    },
    // 获取标题
    getRecommendList () {
      recommendList().then(({ data }) => {
        let row = ''
        if (data.data && data.success === true) {
          // this.tabList = []
          data.data.forEach((ele,index) => {
            if(index<4){
              this.tabList.push(ele)
            }

          });
          row = this.tabList[0].categoryId
        } else {
          this.tabList = []
          row = ''
        }
        this.getRecommendInfoList(row)
      })
    },
    // 获取标题
    getRecommendInfoList (row) {
      let params = {
        limit:5,
        page:1,
        categoryId:row
      }
      recommendInfoList(params).then(({ data }) => {
        if (data.data && data.success === true) {
          this.dataList = data.data.rows
          this.total = data.data.total
          this.imgList = []
          this.dataList.forEach((ele,index) => {
            if(index < 3){
              this.imgList.push(this.filterNull(ele.picUrlList)[0])
            }
          });
        } else {
          this.imgList = []
          this.dataList = []
          this.total = 1
        }
      })
    },
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
    turnPlayer(data){
      let courseId = null
      if(data){
        courseId = data.courseId
      }else{
        courseId = this.dataList[0].courseId
      }
      window.sessionStorage.setItem('Play','course')
      window.sessionStorage.setItem('comboId','')
      window.sessionStorage.setItem('courseId',courseId)
      window.sessionStorage.setItem('courseList',JSON.stringify(this.dataList))
      this.$router.push({
        name: 'player'
      }).catch(err=>err)
    }
  }
};
</script>
<style lang="scss" scoped>
.course-container {
  padding: 40px 0 40px 15px;
  .wrapper {
    height: 380px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(25, 25, 25, 0.85) 85%,
      rgba(41, 41, 41, 1) 100%
    );
    border-radius: 2px;
    .nav-bar {
      height: 88px;
      display: flex;
      align-content: center;
      color: #ffffff;
      align-items: center;
      h3 {
        color: inherit;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 500;
        margin: 0 0 0 30px;
        flex-shrink: 0;
      }
      .nav-tab {
        flex-grow: 1;
        height: 44px;
        &:hover {
          /deep/ .__bar-is-horizontal {
            opacity: 1!important;
          }
        }
        /deep/ .__panel {
          scrollbar-width: none; /* firefox */
          -ms-overflow-style: none; /* IE 10+ */
          &::-webkit-scrollbar {
            display: none; /* Chrome Safari */
          }
        }
        .nav-tab-inner {
          display: flex;
          height: 44px;
          display: flex;
          align-items: center;
        }
        .nav-tab-item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 7%;
          flex-shrink: 0;
          a {
            color: inherit;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 300;
            color: inherit;
            position: relative;
            &:before{
              content: '';
              position: absolute;
              width: 0;
              height: 2px;
              background-color: #4a9afa;
              width: 0;
              left: 0;
              bottom: 0;
            }
          }
          .active {
            font-family: PingFangSC-Medium, PingFang SC;
            &:before{
              width: 100%;
              transition: all 0.15s;
            }

          }
        }
      }
    }
    .course-wrapper {
      width: 100%;
      display: flex;
      .left-img {
        position: relative;
        z-index: 0;
        height: 292px;
        img {
          position: absolute;
          background-repeat: no-repeat;
          background-size: 100%;
        }
        .first-img {
          width: 67%;
          right: 0;
          top: 30px;
          z-index: 3;
        }
        .second-img {
          width: 64%;
          right: 33px;
          top: 64px;
          background-color: gold;
          z-index: 2;
        }
        .third-img {
          width: 56%;
          right: 84px;
          top: 100px;
          background-color: rebeccapurple;
          z-index: 1;
        }
      }
      .right-list {
        padding: 0 30px;
        height: 292px;
        .list-header {
          display: flex;
          justify-content: space-between;
          height: 32px;
          .player {
            display: flex;
            cursor: pointer;
            .play-btn {
              width: 32px;
              height: 32px;
              background: #4a9afa;
              border-radius: 50%;
              position: relative;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: rgba(255, 255, 255, 1);
              display: flex;
              justify-content: center;
              align-items: center;
              .triangle {
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-left: 10px solid #ffffff;
                border-bottom: 6px solid transparent;
                margin-left: 2px;
              }
            }
            h4 {
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(255, 255, 255, 1);
              margin-left: 10px;
              line-height: 32px;
              &:hover{
              color:#4a9afa;
            }
            }
          }
          .turn-list {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #999999;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            .button {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 10px;
            }
          }
        }
        .list-body {
          .list-item {
            margin-top: 27px;
            display: flex;
            font-size: 12px;
            // font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 300;
            color: #ffffff;
            cursor: pointer;
            .index {
              font-weight: 500;
              color: inherit;
            }
            .title {
              flex-grow: 1;
              font-weight: 500;
              color: inherit;
              padding: 0 0px 0 20px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .auth{
              width: 150px;
              text-align: center;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              padding-left: 20px;
              flex-shrink: 0;
            }
            .time {
              width: 60px;
              color: inherit;
              margin-left: 20px;
              display: flex;
              align-items: center;
              font-family: PingFangSC;
              justify-content:flex-end;
              flex-shrink: 0;
              img{
                width: 18px;
                height: 18px;
                margin-left: 5px;
              }
            }
            &:hover{
              color:#4a9afa;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .course-container {
    padding: 40px 10px;
    .wrapper {
      .nav-bar {
        h3 {
          margin-left: 3%;
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .course-container {
    .wrapper {
      height: auto;
      .course-wrapper {
        .left-img {
          height: 40vw;
          .first-img,
          .second-img,
          .third-img {
            top: 50%;
            width: 60%;
            right: 50%;
          }
          .first-img {
            transform: translate(30%, -50%);
          }
          .second-img {
            transform: translate(50%, -50%);
          }
          .third-img {
            transform: translate(70%, -50%);
          }
        }
        .right-list {
          padding: 0 3%;
          .list-body {
            .list-item {
              .auth {
                width: 20vw;
              }
              .time {
                width: 10vw;
              }
            }
          }
        }
      }
    }
  }
}
</style>
