<template>
  <div class="course-container">
    <!-- 标题 -->
    <div class="course-title">
      <h2>优选套餐</h2>
      <a class="turnList" @click="openDialog">
        全部
        <div class="button">
          <svg-icon icon-class="right" class-name="right-icon" />
        </div>
      </a>
    </div>
    <!-- 列表 -->
    <div class="course-list">
      <vue-scroll :ops="vueScrollOps">
        <div class="course-list-inner">
          <div class="course-item" v-for="(item, index) in $formatAdList(dataList, 5)"
               :key="index">
            <template v-if="item.isAdvert">
              <div class="cover" @click="turnDetail(item)">
                <div class="img">
                  <img :src="item.cover" alt />
                </div>
              </div>
              <div class="info introduction"><span class="adTag">广告</span><span>{{ item.title }}</span></div>
              <div class="ad-public">
                <span class="name">{{ item.forShort }}</span>
                <span class="hide" @click.stop="hideAdList(item, index)">隐藏</span>
              </div>
            </template>
            <template v-else>
              <div class="cover" @click="turnDetail(item)">
                <div class="img">
                  <img :src="filterNull(item.picUrlList)[0]" alt />
                </div>
                <div class="count">
                  <div class="triangle"></div>{{item.collectCount}}
                </div>
                <div class="action">
                  <div class="player">
                    <div class="player-triangle"></div>
                  </div>
                </div>
              </div>
              <div class="info introduction">{{item.comboName}}</div>
            </template>
          </div>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>
<script>
import { optimizationList } from "@api/discovery/index";
export default {
  data() {
    return {
      dataList:[],
      vueScrollOps: {
        bar: {
          background: "rgba(0, 0, 0, 0.3)",
          size: this.$getBrowser().versions.mobile ? '0px' : '8px'
        }
      }
    };
  },
  created() {
    this.getOptimizationList();
  },
  computed: {
    comboId:{
      get () { return this.$store.state.common.comboId },
      set (val) {
        this.$store.commit('common/updateComboId', val)
      }
    },
    courseId:{
      get () { return this.$store.state.common.courseId },
      set (val) {
        this.$store.commit('common/updateCourseId', val)
      }
    }
  },
  components: {

  },
  methods: {
    // 获取热门课程
    getOptimizationList() {
      let params = {
        limit:5,
        page:1,
        labelIds:''
      }
      optimizationList(params).then(({ data }) => {
        if (data.data && data.success === true) {
          this.dataList = data.data.rows
        } else {
          this.dataList = []
        }
      });
    },
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
    turnDetail(data){
      if (data.isAdvert) {
        this.$root.$emit('openDialog', { type: 'dialog' })
        return
      }
      this.comboId = data.comboId
      this.courseId = ''
      window.sessionStorage.setItem("comboId", data.comboId);
      window.sessionStorage.setItem("courseId", '');
      this.$router.push({
        name: 'setMeal'
      }).catch(err=>err)
    },
    // 隐藏广告
    hideAdList (item, hideIndex) {
      this.dataList = this.$formatAdList(this.dataList, 5, hideIndex)
    }
  },
};
</script>
<style lang="scss" scoped>
.course-container {
  padding-left: 15px;
  // 标题
  .course-title {
    display: flex;
    align-items: center;
    height: 84px;
    justify-content: space-between;
    h2 {
      font-size: 24px;
      font-weight: 500;
      color: #4a4a4a;
    }
    .turnList {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      .button {
        width: 53px;
        height: 53px;
        background-color: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;
      }
    }
    .other {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .count {
        margin-left: 15px;
        font-size: 64px;
        color: #f8f8f8;
      }
      a {
        display: flex;
        justify-content: flex-end;
        .turnList {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 300;
        }
      }
    }
  }
  // 列表
  .course-list {
    height: 152px;
    &:hover {
      /deep/ .__bar-is-horizontal {
        opacity: 1!important;
      }
    }
    /deep/ .__panel {
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
    }
    .course-list-inner {
      min-width: 877px;
      display: flex;
      justify-content: space-between;
    }
    .course-item{
      &:nth-of-type(1){
        margin-left: 0;
      }
    }
    .course-item {
      position: relative;
      padding-right: 10px;
      &:last-child {
        padding-right: 0;
      }
      .cover {
        position: relative;
        padding-right: 9px;
        cursor: pointer;
        z-index: 0;
        &:before,
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #d9d9d9;
          position: absolute;
          top: 0;
        }
        &:before {
          border: 0.5px solid #d6d6d6;
          right: 4px;
          transform: scale(0.85);
          transform-origin: 100% 50%;
          z-index: 2;
          border-radius: 2px;
        }
        &:after {
          border: 0.5px solid #d6d6d6;
          right: 0;
          transform: scale(0.73);
          transform-origin: 100% 50%;
          z-index: 1;
          border-radius: 2px;
          opacity: 0.5;
        }
        .img {
          position: relative;
          z-index: 3;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 150px;
            height: 84px;
          }
        }
        .count {
          z-index: 4;
          position: absolute;
          right: 9px;
          top: 10px;
          padding-left: 15px;
          padding-right: 6px;
          background: #000;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          height: 17px;
          font-size: 12px;
          font-weight: 300;
          color: rgba(255, 255, 255, 1);
          line-height: 17px;
        }
        .triangle {
          position: absolute;
          left: 7px;
          top: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 6px solid #ffffff;
          border-bottom: 3px solid transparent;
        }
        .action{
          width: 100%;
          height: 84px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 3;
          display: none;
          justify-content: center;
          align-items: center;
          .player{
            float: right;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background: #FFFFFF;
            display: flex;
            justify-content: center;
            align-items: center;
            .player-triangle{
              width: 0;
              height: 0;
              border-top: 6px solid transparent;
              border-left: 10px solid #000000;
              border-bottom: 6px solid transparent;
              margin-left: 2px;
            }
          }
        }
        &:hover{
          .action{
            display: flex;
          }
        }
      }
      .info {
        margin-top: 14px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
      }
      .introduction {
        width: 150px;
        height: 24px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        .adTag {
          border-radius: 4px;
          padding: 0 2px;
          font-size: 12px;
          color: #C1C1C1;
          border: 1px solid #C1C1C1;
          margin-right: 5px;
        }
      }
      .ad-public {
        color: #999999;
        margin-top: 8px;
        display: flex;
        justify-content: space-between;
        width: 150px;
        font-size: 12px;
        .name {
          color: #999;
        }
        .hide {
          cursor: pointer;
          color: #C1C1C1;
        }
      }
    }
  }
}
@media only screen and (max-width: 920px) {
  .course-container {
    padding: 0 10px;
    .course-title {
      .turnList {
        .button {
          width: 8vw;
          height: 8vw;
          max-width: 53px;
          max-height: 53px;
        }
      }
    }
  }
}
</style>
