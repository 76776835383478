<template>
  <div class="loading-wrapper">
    <div class="sk-cube-grid">
      <div class="sk-cube sk-cube1"></div>
      <div class="sk-cube sk-cube2"></div>
      <div class="sk-cube sk-cube3"></div>
      <div class="sk-cube sk-cube4"></div>
      <div class="sk-cube sk-cube5"></div>
      <div class="sk-cube sk-cube6"></div>
      <div class="sk-cube sk-cube7"></div>
      <div class="sk-cube sk-cube8"></div>
      <div class="sk-cube sk-cube9"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.loading-wrapper {
  width: 100%;
  height: 140px;
  overflow: hidden;
  z-index: 9999;
  position: relative;
  .sk-cube-grid {
    display: block !important;
    z-index: 10000;
    // display: none;
    width: 60px;
    height: 60px;
    top: 50%;
    position: absolute;
    left: 50%;
    margin: -30px 0 0 -30px;
    .sk-cube {
      width: 33%;
      height: 33%;
      // background-color: #fff;
      background-image: url(~@assets/images/logo_big.png);
      background-repeat: no-repeat;
      background-size: 300%;
      float: left;
      -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
      animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    }
    .sk-cube1 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      background-position: 0 0;
    }
    .sk-cube2 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
      background-position: 50% 0;
    }
    .sk-cube3 {
      -webkit-animation-delay: 0.4s;
      animation-delay: 0.4s;
      background-position: 100% 0;
    }
    .sk-cube4 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      background-position: 0 50%;
    }
    .sk-cube5 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      background-position: 50% 50%;
    }
    .sk-cube6 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
      background-position: 100% 50%;
    }
    .sk-cube7 {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      background-position: 0 100%;
    }
    .sk-cube8 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      background-position: 50% 100%;
    }
    .sk-cube9 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      background-position: 100% 100%;
    }
  }
}
@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  100%,
  70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
@keyframes sk-cubeGridScaleDelay {
  0%,
  100%,
  70% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }

  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}
</style>