import http from '../utils/httpRequest'

// POST 请求
function httpPost (url, params, flag = false) {
  return http({
    method: 'post',
    url: http.adornUrl(url),
    data: http.adornData(params, flag)
  })
}

// POST 请求
function httpPostFile (url, params) {
  return http({
    method: 'post',
    url: http.adornUrl(url),
    data: params
  })
}

// PUT 请求
function httpPut (url, params, flag = true) {
  return http({
    method: 'put',
    url: http.adornUrl(url),
    data: http.adornData(params, flag)
  })
}
// DELETE 请求
function httpDelete (url, params, flag = true) {
  return http({
    method: 'delete',
    url: http.adornUrl(url),
    data: http.adornData(params, flag)
  })
}
// GET 请求
function httpGet (url, data = {}, flag = true) {
  if (data.utcParam) { // utcParam 为数组
    const router = data.utcParam.join('/')
    url = `${url}/${router}`
    delete data.utcParam
  }

  return http({
    method: 'get',
    url: http.adornUrl(url),
    params: data ? http.adornParams(data, flag) : http.adornParams()
  })
}
export {
  httpPost,
  httpPostFile,
  httpGet,
  httpPut,
  httpDelete
}
