<template>
  <div class="player-body">
    <div class="player-wrapper">
      <a-row type="flex" justfiy="center" :gutter="31">
        <a-col :xs="24" :md="14" :lg="16">
          <div class="player">
            <div class="player-video" ref="playVideoBox" :style="{ height: `${playVideoBoxHeight}px` }">
              <div v-show="trialPlayStatus">
                <img class="player-poster" :src="courseDetail.picUrlList[0]"/>
                <div class="player-cover">
                  <div class="download">
                    <img v-if="qrcodeType != 3" class="qrcode-img" src="@assets/images/qrcode.png" alt="qcrode" />
                    <img v-else class="qrcode-img" src="@assets/images/xcx_qrcode.jpg" alt="qcrode" />
                    <!--          TODO 缺ICON和阴影样式          -->
                    <div class="platform">
                      <a href="javascript:void(0)" @click="qrcodeType = 1" :class="{ 'on': qrcodeType == 1 }">
                        <div class="icon ios">
                          <svg-icon icon-class="apple" class-name="download-icon apple" />
                        </div>
                      </a>
                      <a href="javascript:void(0)" @click="qrcodeType = 2" :class="{ 'on': qrcodeType == 2 }">
                        <div class="icon android">
                          <svg-icon icon-class="android" class-name="download-icon" />
                        </div>
                      </a>
                      <a href="javascript:void(0)" @click="qrcodeType = 3" :class="{ 'on': qrcodeType == 3 }">
                        <div class="icon xcx">
                          <svg-icon icon-class="xiaochengxu" class-name="download-icon" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <p>该课程为付费课程，请移步到{{ qrcodeType == 3 ? '小程序' : 'APP' }}进行购买</p>
                </div>
              </div>
              <div v-show="!trialPlayStatus">
                <div class="freeTime" v-if="courseDetail.chargeType === 2">免费试看：<span>{{ freeTimeCount }}</span>秒</div>
                <video
                  ref="courseVideo"
                  controls
                  controlslist="nodownload"
                  playsinline
                  :autoplay="false"
                  :poster="courseDetail.picUrlList[0]"
                  :src="courseDetail.videoUrl"
                ></video>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :md="10" :lg="8">
          <set-meal-player-list
            v-if="playStatus === 'combo'"
            ref="setMealPlayerList"
            :playVideoBoxHeight="playVideoBoxHeight"
          ></set-meal-player-list>
          <player-list
            v-else
            ref="playerList"
            :playVideoBoxHeight="playVideoBoxHeight"
          ></player-list>
        </a-col>
      </a-row>
    </div>
    <div class="course-info">
      <div class="block-title">课程简介</div>
      <div class="course-wrapper">
        <div class="cover">
          <!-- <div style="width:350px;height:196px;background-color:green"></div> -->
          <img
            v-if="courseDetail.picUrlList"
            :src="courseDetail.picUrlList[0]"
          />
        </div>
        <div class="course-wrapper-content course-wrapper-content-1">
          <div class="nav">
            <div class="title">{{ courseDetail.courseName }}</div>
            <div class="information">
              <div class="logo">
                <img :src="courseDetail.offerPicUrl" />
              </div>
              <div class="info name">{{ courseDetail.offerUserName }}</div>
              <div class="info time">{{ courseDetail.createTime }}</div>
              <div class="button">
                <div class="button-btn xihuan-btn">
                  <svg-icon icon-class="xihuan" class-name="xihuan-icon" />{{
                    courseDetail.collectCount
                  }}
                </div>
                <!-- <div class="button-btn shares-btn">
                  <svg-icon icon-class="shares" class-name="sharess-icon" />分享
                </div> -->
              </div>
            </div>
          </div>
          <div class="introduction">
            <b>课程介绍：</b>{{ courseDetail.describes }}
          </div>
        </div>
      </div>
      <div class="course-wrapper-content course-wrapper-content-2">
        <div class="nav">
          <div class="information">
            <div class="logo">
              <img :src="courseDetail.offerPicUrl" />
            </div>
            <div class="info name">{{ courseDetail.offerUserName }}</div>
            <div class="info time">{{ courseDetail.createTime }}</div>
            <div class="button">
              <div class="button-btn xihuan-btn">
                <svg-icon icon-class="xihuan" class-name="xihuan-icon" />{{
                courseDetail.collectCount
                }}
              </div>
              <!-- <div class="button-btn shares-btn">
                <svg-icon icon-class="shares" class-name="sharess-icon" />分享
              </div> -->
            </div>
          </div>
        </div>
        <div class="introduction">
          <b>课程介绍：</b>{{ courseDetail.describes }}
        </div>
      </div>
    </div>
    <div class="preferred">
      <div class="block-title">
        优选套餐
        <a class="turnList" @click="openDialog">
          全部
          <div class="button">
            <svg-icon icon-class="right" class-name="right-icon" />
          </div>
        </a>
      </div>
      <div class="contantior">
        <vue-scroll :ops="vueScrollOps">
          <div class="contantior-inner">
            <div
              class="course-item"
              v-for="(item, index) in $formatAdList(prefDataList, 5)"
              :key="index"
              @click="turnDetail(item)"
            >
              <template v-if="item.isAdvert">
                <div class="cover" @click="turnDetail(item)">
                  <div class="img">
                    <img :src="item.cover" alt />
                  </div>
                </div>
                <div class="info introduction"><span class="adTag">广告</span><span>{{ item.title }}</span></div>
                <div class="ad-public">
                  <span class="name">{{ item.forShort }}</span>
                  <span class="hide" @click.stop="hideAdList(item, index)">隐藏</span>
                </div>
              </template>
              <template v-else>
                <div class="cover">
                  <div class="img">
                    <img :src="filterNull(item.picUrlList)[0]" alt />
                    <!-- <div style="width:210px;height:118px;background-color:green"></div> -->
                  </div>
                  <div class="action">
                    <div class="player">
                      <div class="player-triangle"></div>
                    </div>
                  </div>
                </div>
                <div class="info">{{ item.comboName }}</div>
              </template>
            </div>
          </div>
        </vue-scroll>
      </div>
    </div>
  </div>
</template>
<script>
import { courseDetail } from "@api/player/index";
import { optimizationList } from "@api/discovery/index";
import setMealPlayerList from "../setMeal/base/playerList";
import playerList from "./base/playerList";
export default {
  metaInfo: {
    title: "课程观看 - 睿识课",
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是北京睿识课科技有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  data() {
    return {
      courseDetail: { picUrlList: [] },
      courseCount: 0,
      prefDataList: [],
      playStatus: "",
      videoRef: null,
      trialPlayStatus: false,
      playVideoBoxHeight: 0,
      freeTimeCount: 10,
      vueScrollOps: {
        bar: {
          background: "rgba(0, 0, 0, 0.3)",
          size: this.$getBrowser().versions.mobile ? '0px' : '8px'
        }
      },
      qrcodeType: 1
    };
  },
  components: {
    setMealPlayerList,
    playerList
  },
  computed: {
    searchName: {
      get() {
        return this.$store.state.common.searchName;
      },
    },
    comboId: {
      get() {
        return this.$store.state.common.comboId;
      },
      set(val) {
        this.$store.commit("common/updateComboId", val);
      },
    },
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  watch: {
    courseId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getCourseDetail();
      }
    },
  },
  activated() {
    this.playStatus = window.sessionStorage.getItem("Play");
    this.comboId = window.sessionStorage.getItem("comboId");
    this.courseId = window.sessionStorage.getItem("courseId");
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.getCourseDetail();
    this.getOptimizationList();
    this.$nextTick(()=>{
      if(this.playStatus === 'combo'){
        this.$refs.setMealPlayerList.getComboDetail()
      }else{
        this.$refs.playerList.getAiCourse()
      }
    })
  },
  mounted () {
    this.videoRef = this.$refs.courseVideo;
    this.getPlayVideoBoxHeight();
    window.addEventListener('resize', this.getPlayVideoBoxHeight, false);
  },
  destroyed() {
    window.removeEventListener('resize', this.getPlayVideoBoxHeight);
  },
  methods: {
    getPlayVideoBoxHeight () {
      this.playVideoBoxHeight = this.$refs.playVideoBox.clientWidth * 450 / 799;
    },
    // 获取课程数据
    getCourseDetail() {
      this.videoRef.removeEventListener("timeupdate", this.videoPause);
      this.videoRef.pause();
      this.trialPlayStatus = false;
      this.freeTimeCount = 10;
      let params = { courseId: this.courseId };
      courseDetail(params).then(({ data }) => {
        if (data && data.success === true) {
          this.courseDetail = data.data;
          // 动态修改标签标题
          document.title = `${data.data.courseName} - ${data.data.categoryName} - ${window.GLOBALTITLE}`;
          if (data.data.chargeType === 2) {
            // 获取当前课程播放时间并显示下载APP
            this.videoRef.addEventListener("timeupdate", this.videoPause)
          }
        } else {
          this.courseDetail = { picUrlList: [] };
        }
      });
    },
    // 播放暂停
    videoPause () {
      // 大于10秒时暂停
      this.freeTimeCount = parseInt(10 - this.videoRef.currentTime)
      if (this.videoRef.currentTime > 10) {
        this.videoRef.removeEventListener("timeupdate", this.videoPause);
        this.videoRef.pause();
        this.trialPlayStatus = true;
      }
    },
    // 获取热门课程
    getOptimizationList() {
      let params = {
        limit: 5,
        page: 1,
        labelIds: "",
      };
      optimizationList(params).then(({ data }) => {
        if (data.data && data.success === true) {
          this.prefDataList = data.data.rows.slice(0,5);
        } else {
          this.prefDataList = [];
        }
      });
    },
    // 打开下载弹窗
    openDialog() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    },
    turnDetail(data) {
      if (data.isAdvert) {
        this.$root.$emit('openDialog', { type: 'dialog' })
        return
      }
      this.comboId = data.comboId;
      this.courseId = "";
      window.sessionStorage.setItem("comboId", data.comboId);
      window.sessionStorage.setItem("courseId", '');
      this.$router
        .push({
          name: "setMeal",
        })
        .catch((err) => err);
    },
    // 隐藏广告
    hideAdList (item, hideIndex) {
      this.prefDataList = this.$formatAdList(this.prefDataList, 5, hideIndex)
    }
  },
};
</script>
<style lang="scss" scoped>
.player-body {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  padding-top: 15px;
  .player-wrapper {
    .player {
      .player-video {
        width: 100%;
        position: relative;
        overflow: hidden;
        video {
          height: 100%;
          width: 100%;
          border: 1px solid #d8d8d8;
          border-radius: 2px;
          &:focus {
            outline: none;
          }
        }
        .freeTime {
          position: absolute;
          top: 10px;
          left: 10px;
          font-size: 14px;
          color: #fff;
          background: rgba(0, 0, 0, 0.5);
          padding: 5px 10px;
          border-radius: 18px;
          span {
            padding-right: 5px;
            font-size: 16px;
            color: $--color-primary;
          }
        }
        .player-poster {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }
        .player-cover {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 2;
          background: linear-gradient(
            360deg,
            rgba(0, 0, 0, 0.65) 0%,
            rgba(0, 0, 0, 0.81) 100%
          );
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .download {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 70%;
            margin-bottom: 20px;
            .qrcode-img {
              height: 100%;
            }
            .platform {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              margin-left: 2vw;
              height: 100%;
              .icon {
                width: 5vw;
                height: 5vw;
                border-radius: 50%;
                cursor: pointer;
                text-align: center;
                line-height: 5vw;
                .download-icon {
                  font-size: 3vw;
                  display: inline-block;
                  vertical-align: middle;
                  text-align: left;
                  white-space: normal;
                }
              }
              .ios {
                background-color: #41474d;
              }
              .android {
                background-color: #54c77a;
              }
              .xcx {
                background-color: #54c77a;
                .download-icon {
                  font-size: 2.5vw;
                }
              }
              .on {
                // box-shadow: 0 0 20px #fff;
                border-radius: 50%;
                .icon {
                  box-shadow: inset 0 0 60px #fff;
                }
              }
            }
          }
          p {
            font-size: 24px;
            font-weight: 300;
            color: #ffffff;
            text-shadow: 0px 1px 4px rgba(155, 155, 155, 0.5);
            margin: 0;
          }
        }
      }
    }
  }
  .course-info {
    margin-top: 40px;
    .course-wrapper {
      display: flex;
      .cover {
        padding-right: 32px;
        position: relative;
        img {
          width: 350px;
          height: 196px;
        }
      }
    }
    .course-wrapper-content {
      .nav {
        margin-top: -6px;
        color: $--title-color;
        .title {
          width: 100%;
          color: inherit;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
        }
        .information {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 14px 0;
          .logo {
            border-radius: 50%;
            overflow: hidden;
            margin-right: 5px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          .info {
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: rgba(171, 171, 171, 1);
            line-height: 14px;
            margin-right: 10px;
          }
        }
        .button {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          .button-btn {
            width: 130px;
            height: 40px;
            border-radius: 20px;
            line-height: 40px;
            position: relative;
            // padding-left: 22px;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            cursor: pointer;
            margin-right: 14px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .xihuan-btn,
          .shares-btn {
            background-color: #f2f2f2;
            color: #6e6e6e;
            .xihuan-icon,
            .sharess-icon {
              // margin-left: 14px;
              margin-right: 4px;
              width: 16px;
              height: 16px;
            }
          }
        }
      }
      .introduction {
        b {
          font-size: 16px;
        }
      }
    }
    .course-wrapper-content-2 {
      display: none;
    }
  }
  .preferred {
    margin-top: 40px;
    margin-bottom: 50px;
    .turnList {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 300;
      .button {
        width: 53px;
        height: 53px;
        background-color: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 14px;
      }
    }
    .contantior {
      height: 190px;
      &:hover {
        /deep/ .__bar-is-horizontal {
          opacity: 1!important;
        }
      }
      /deep/ .__panel {
        scrollbar-width: none; /* firefox */
        -ms-overflow-style: none; /* IE 10+ */
        &::-webkit-scrollbar {
          display: none; /* Chrome Safari */
        }
      }
      .contantior-inner {
        display: flex;
        justify-content: space-between;
      }
      .course-item {
        position: relative;
        padding-right: 10px;
        &:last-child {
          padding-right: 0;
        }
        .cover {
          position: relative;
          padding-right: 17px;
          cursor: pointer;
          &:before,
          &:after {
            content: "";
            width: 100%;
            height: 100%;
            background-color: #d9d9d9;
            position: absolute;
            top: 0;
          }
          &:before {
            border: 0.5px solid #d6d6d6;
            right: 8px;
            transform: scale(0.85);
            transform-origin: 100% 50%;
            z-index: 2;
            border-radius: 2px;
          }
          &:after {
            border: 0.5px solid #c4c4c4;
            right: 0;
            transform: scale(0.73);
            transform-origin: 100% 50%;
            z-index: 1;
            border-radius: 2px;
            opacity: 0.5;
          }
          .img {
            position: relative;
            z-index: 3;
            border-radius: 2px;
            overflow: hidden;
            img {
              width: 210px;
              height: 118px;
            }
          }
          .action {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 3;
            display: none;
            justify-content: center;
            align-items: center;
            .player {
              float: right;
              width: 32px;
              height: 32px;
              border-radius: 50%;
              background: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              .player-triangle {
                width: 0;
                height: 0;
                border-top: 6px solid transparent;
                border-left: 10px solid #000000;
                border-bottom: 6px solid transparent;
                margin-left: 2px;
              }
            }
          }
          &:hover {
            .action {
              display: flex;
            }
          }
        }
        .info {
          width: 210px;
          margin-top: 14px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4a4a4a;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          .adTag {
            border-radius: 4px;
            padding: 0 2px;
            font-size: 12px;
            color: #C1C1C1;
            border: 1px solid #C1C1C1;
            margin-right: 5px;
          }
        }
        .ad-public {
          color: #999999;
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
          width: 210px;
          font-size: 12px;
          .name {
            color: #999;
          }
          .hide {
            cursor: pointer;
            color: #C1C1C1;
          }
        }
      }
    }
  }
  .block-title {
    font-size: 24px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #4a4a4a;
    line-height: 84px;
  }
}
@media only screen and (max-width: 1230px) {
  .player-body {
    .course-info,
    .preferred {
      padding: 0 10px;
    }
  }
}
@media only screen and (min-width: 770px) and (max-width: 790px) {
  .player-body {
    .course-info {
      .course-wrapper-content {
        .nav {
          .button {
            .button-btn {
              width: 60px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .player-body {
    .player-wrapper {
      .player {
        .player-video {
          .freeTime {
            font-size: 2vw;
            span {
              font-size: 3vw;
            }
          }
          .player-cover {
            .download {
              .platform {
                margin-left: 15vw;
                .icon {
                  width: 9vw;
                  height: 9vw;
                  line-height: 9vw;
                  .download-icon {
                    font-size: 6vw;
                  }
                }
                .xcx {
                  .download-icon {
                    font-size: 4.5vw;
                  }
                }
              }
            }
            p {
              font-size: 4vw;
            }
          }
        }
      }
    }
    .course-info {
      .course-wrapper {
        .cover {
          padding-right: 10px;
          img {
            width: 40vw;
            height: auto;
          }
        }
      }
    }
    .preferred {
      .turnList {
        .button {
          width: 8vw;
          height: 8vw;
          max-width: 53px;
          max-height: 53px;
        }
      }
    }
  }
}
@media only screen and (min-width: 576px) and (max-width: 700px) {
  .player-body {
    .course-info {
      .course-wrapper-content {
        .nav {
          .button {
            .button-btn {
              width: 60px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .player-body {
    .course-info {
      .course-wrapper {
        .course-wrapper-content-1 {
          .nav {
            .title {
              font-size: 5vw;
            }
            .information {
              display: none;
            }
          }
          .introduction {
            display: none;
          }
        }
      }
      .course-wrapper-content-2 {
        display: block;
      }
    }
  }
}
@media only screen and (max-width: 410px) {
  .player-body {
    .course-info {
      .course-wrapper-content {
        .nav {
          .button {
            .button-btn {
              width: 60px;
            }
          }
        }
      }
    }
  }
}
</style>
