<template>
  <div class="setMeal">
    <div v-if="!isLoading">
      <div v-if="dataList && dataList.length>0">
        <div class="contantior">
        <div class="course-item" v-for="(item, index) in dataList" :key="index">
          <div class="cover" @click="turnDetail(item)">
            <div class="img">
              <img :src="item.picUrlList[0]" alt="头像" />
            </div>
            <div class="count">
              <div class="triangle"></div>
              {{ item.paymentCount === null ? getRndInteger(100,300) : item.paymentCount }}
            </div>
          </div>
          <div class="info introduction" @click="turnDetail(item)">
            {{ item.comboName }}
          </div>
          <div class="info name">{{ item.offerUserName }}</div>
          <div class="nav-tag">
            <div class="tags" @click="turnDetail(item)">
              <span
                class="tag-item"
                v-for="(it, ind) in item.labelName.split('|')"
                :key="ind"
              >
                <a style="cursor: auto">#{{ it }}</a>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="page">
        <pagination
          v-if="pageVisiable"
          :current="page"
          :total="total"
          @change="getDataList"
        />
      </div>
      </div>
      <div v-else class="no-results">
        <img src="@assets/images/noResults.png" alt />
        <p>搜索不到您要的结果，换个关键词试试哦~</p>
      </div>
    </div>
    <loading v-if="isLoading"></loading>
  </div>
</template>
<script>
import { comboList } from "@api/search/index";
import loading from "@/components/loading";
import pagination from "ant-design-vue/lib/pagination";
import "ant-design-vue/lib/pagination/style/css";
export default {
  data() {
    return {
      isLoading: false,
      total: 1,
      page: 1,
      limit: 50,
      dataList: [],
      pageVisiable: false,
    };
  },
  components: {
    loading,
    pagination,
  },
  computed: {
    searchName: {
      get() {
        return this.$store.state.common.searchName;
      },
    },
    comboId: {
      get() {
        return this.$store.state.common.comboId;
      },
      set(val) {
        this.$store.commit("common/updateComboId", val);
      },
    },
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  watch: {
    searchName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getDataList();
      }
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) ) + min;
    },
    // 获取数据列表
    getDataList() {
      let params = {
        name: this.searchName || window.sessionStorage.getItem("searchName"),
        page: this.page,
        limit: this.limit,
        queryAllCount: true,
      };
      this.isLoading = true;
      comboList(params).then(({ data }) => {
        if (data && data.success === true) {
          this.dataList = data.data.rows;
          this.total = data.data.total;
          if (this.total !== null && this.dataList.length > 50) {
            this.pageVisiable = true;
          } else {
            this.pageVisiable = false;
          }
        } else {
          this.dataList = [];
          this.total = 0;
          this.pageVisiable = false;
        }
        this.isLoading = false;
      });
    },
    turnDetail(data) {
      this.comboId = data.comboId;
      this.courseId = "";
      window.sessionStorage.setItem("comboId", data.comboId);
      window.sessionStorage.setItem("courseId", '');
      this.$router
        .push({
          name: "setMeal",
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="scss" scoped>
$coverPR: 17px;
.setMeal {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  margin-top: 30px;
  margin-bottom: 50px;
  .turnList {
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 300;
    .button {
      width: 53px;
      height: 53px;
      background-color: #f2f2f2;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 14px;
    }
  }
  .contantior {
    width: 100%;
    overflow: hidden;
    .course-item {
      float: left;
      margin-right: 22px;
      margin-bottom: 30px;
      position: relative;
      .cover {
        position: relative;
        cursor: pointer;
        padding-right: $coverPR;
        &:before,
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #d9d9d9;
          position: absolute;
          top: 0;
        }
        &:before {
          border: 0.5px solid #d6d6d6;
          right: 8px;
          transform: scale(0.85);
          transform-origin: 100% 50%;
          z-index: 2;
          border-radius: 2px;
        }
        &:after {
          border: 0.5px solid #c4c4c4;
          right: 0;
          transform: scale(0.73);
          transform-origin: 100% 50%;
          z-index: 1;
          border-radius: 2px;
          opacity: 0.5;
        }
        .img {
          position: relative;
          z-index: 3;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 210px;
            height: 118px;
          }
        }
        .count {
          z-index: 4;
          position: absolute;
          right: $coverPR;
          top: 10px;
          padding-left: 15px;
          padding-right: 6px;
          background: #000;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          height: 17px;
          font-size: 12px;
          font-weight: 300;
          color: rgba(255, 255, 255, 1);
          line-height: 17px;
        }
        .triangle {
          position: absolute;
          left: 7px;
          top: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 6px solid #ffffff;
          border-bottom: 3px solid transparent;
        }
      }
      .info {
        width: 227px;
        margin-top: 14px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #4a4a4a;
        word-wrap: break-word;
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .introduction {
        height: 21px;
      }
      .name {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ababab;
        line-height: 12px;
        margin-top: 10px;
      }
      .nav-tag {
        .tags {
          width: 227px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          overflow: hidden;
          color: rgba(74, 154, 250, 1);
          word-wrap: break-word;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          /*! autoprefixer: off */
          -webkit-box-orient: vertical;
          /* autoprefixer: on */
          .tag-item {
            margin-right: 10px;
            a {
              color: inherit;
            }
          }
        }
      }
    }
  }
  .no-results{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
  }
}
.page {
  margin: 70px 0;
  text-align: right;
  /deep/.ant-pagination-item-link {
    color: #999999;
  }
  /deep/.ant-pagination-item {
    a {
      color: #999999;
    }
  }
  /deep/.ant-pagination-item-active {
    background-color: #4a9afa !important;
    a {
      color: #ffffff;
    }
  }
}
@media only screen and (min-width: 1230px) {
  .setMeal {
    .contantior {
      .course-item {
        &:nth-of-type(5n+0) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) and (max-width: 1230px) {
  $calcWidth: calc(21vw - #{$coverPR});
  .setMeal {
    .contantior {
      .course-item {
        margin-right: 3vw;
        .cover {
          .img {
            img {
              width: $calcWidth;
              height: calc((21vw - #{$coverPR}) * 118 / 210);
            }
          }
        }
        .info {
          width: $calcWidth;
        }
        .nav-tag {
          .tags {
            width: $calcWidth;
          }
        }
        &:nth-of-type(4n+0) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (min-width: 630px) and (max-width: 768px) {
  $calcWidth: calc(30vw - #{$coverPR});
  .setMeal {
    .contantior {
      .course-item {
        margin-right: 2vw;
        .cover {
          .img {
            img {
              width: $calcWidth;
              height: calc((30vw - #{$coverPR}) * 118 / 210);
            }
          }
        }
        .info {
          width: $calcWidth;
        }
        .nav-tag {
          .tags {
            width: $calcWidth;
          }
        }
        &:nth-of-type(3n+0) {
          margin-right: 0;
        }
      }
    }
  }
}
@media only screen and (max-width: 630px) {
  /* 10px 是.search-body左右的padding */
  $calcWidth: calc(45vw - 10px - #{$coverPR});
  .setMeal {
    .contantior {
      .course-item {
        margin-right: 5vw;
        .cover {
          .img {
            img {
              width: $calcWidth;
              height: calc((45vw - #{$coverPR}) * 118 / 210);
            }
          }
        }
        .info {
          width: $calcWidth;
        }
        .nav-tag {
          .tags {
            width: $calcWidth;
          }
        }
        &:nth-of-type(2n+0) {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
