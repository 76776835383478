<template>
  <div class="player-list">
    <div class="nav-bar">
      <div class="nav">
        <div class="title">{{comboInfo.comboName}}</div>
        <div class="information">
          <div class="logo">
            <img :src="comboInfo.offerPicUrl" alt />
          </div>
          <div class="info name">{{comboInfo.offerUserName}}</div>
          <div class="info time">2020—02-12</div>
          <div class="info total">共{{comboCourseNum}}节课</div>
        </div>
        <div class="nav-tag">
          <div class="tags" v-if="comboInfo.labelName">
            <span
              class="tag-item"
              v-for="(it, ind) in comboInfo.labelName.split('|')"
              :key="ind"
            >
              <a>#{{it.replace(/^\s+|\s+$/g,"")}}</a>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="list" :style="{ height: `calc(${playVideoBoxHeight}px - 123px)` }">
      <div class="list-item" v-for="(item,index) in comboCourse" :key="index" @click="playCourse(item)">
        <div :class="'index '+ (courseId == item.courseId ? 'active':'')">{{ (index+1) > 9 ? (index+1):('0'+(index+1)) }}</div>
        <div :class="'title ' + (courseId == item.courseId ? 'active':'')">{{item.courseName}}</div>
        <div :class="'time ' + (courseId == item.courseId ? 'active':'')">{{ item.lookCount }} <img src="@assets/images/watch.png" alt="watch" /></div>
      </div>
    </div>
  </div>
</template>
<script>
import { comboDetail,comboCourseList } from '@api/player/index';
export default {
  props: [
    'playVideoBoxHeight'
  ],
  data() {
    return {
      comboInfo:{},
      comboCourse:[],
      comboCourseNum:0,
      tryAndSeeCourse:[],
      pageVisiable:false
    };
  },
  components: {
  },
  computed: {
    comboId:{
      get () { return this.$store.state.common.comboId },
      set (val) {
        this.$store.commit('common/updateComboId', val)
      }
    },
    courseId:{
      get () { return this.$store.state.common.courseId },
      set (val) {
        this.$store.commit('common/updateCourseId', val)
      }
    }
  },
  activated(){
    this.getComboDetail ()
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) ) + min;
    },
    // 获取套餐数据
    getComboDetail () {
      this.comboId = window.sessionStorage.getItem('comboId')
      this.courseId = window.sessionStorage.getItem('courseId')
      let params = { comboId:this.comboId }
      comboDetail(params).then(({ data }) => {
        if (data && data.success === true) {
          this.comboInfo = data.data
          return comboCourseList(params).then(({ data }) => {
            if (data && data.success === true) {
              this.comboCourseNum = data.data.courseCount
              let comboCourse = [...data.data.electiveList, ...data.data.requiredList]
              comboCourse = comboCourse.map(v => {
                v.lookCount = v.lookCount || this.getRndInteger(100,300)
                return v
              })
              this.comboCourse = comboCourse
              this.tryAndSeeCourse = data.data.tryAndSeeCourse
            } else {
              this.comboCourseNum = 0
              this.comboCourse = []
              console.log(data.message)
            }
          })
        } else {
          this.comboInfo = {}
          console.log(data.message)
        }
      })
    },
    // 选中播放
    playCourse(data){
      this.courseId = data.courseId
      window.sessionStorage.setItem('courseId',data.courseId)
    }
  }
};
</script>
<style lang="scss" scoped>
.player-list {
  width: 100%;
  .nav-bar {
    margin-top: -6px;
    .nav {
      color: $--title-color;
      .title {
        width: 100%;
        color: inherit;
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
      }
      .information {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 14px 0 5px;
        .logo {
          border-radius: 50%;
          overflow: hidden;
          margin-right: 5px;
          img {
            width: 32px;
            height: 32px;
          }
        }
        .info {
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: rgba(171, 171, 171, 1);
          line-height: 14px;
          margin-right: 10px;
        }
      }
      .nav-tag {
        margin-left: 32px;
        .tags {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(74, 154, 250, 1);
          height: 36px;
          .tag-item {
            margin-right: 10px;
            a {
              color: inherit;
              cursor: default;
            }
          }
        }
      }
    }
  }
  .list {
    overflow-y: auto;
    .list-item {
      display: flex;
      justify-content: space-between;
      color: #4a4a4a;
      font-family: PingFangSC-Light, PingFang SC;
      font-size: 16px;
      font-weight: 500;
      line-height: 32px;
      margin-top: 36px;
      cursor: pointer;
      .index {
        padding-left: 7px;
        color: inherit;
      }
      .title {
        color: inherit;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        padding: 0 40px;
        flex-grow: 1;
      }
      .time {
        font-size: 14px;
        font-weight: 300;
        color: inherit;
        display: flex;
        align-items: center;
        img{
          width: 18px;
          height: 18px;
          margin-left: 5px;
        }
      }
      .active {
        color: #4a9afa;
      }
    }
  }
}
@media only screen and (max-width: 960px) {
  .player-list {
    .list {
      .list-item {
        margin-top: 15px;
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .player-list {
    padding: 0 10px;
    .nav-bar {
      margin-top: 20px;
    }
    .list {
      height: 200px!important;
    }
  }
}
</style>
