<template>
  <div class="mobile-body">
    <!--第一块-->
    <section class="FirstPage">
      <div class="bgblue"></div>
      <figure>
        <div class="SafetyBOX clearfix">
          <div class="SafetyBOX_left">
            <h3>互联网安全科普平台</h3>
            <div class="SafetyBOX_right phonePage pc">
              <img src="@assets/images/mobile/img1.png" alt="" />
            </div>
            <p>
              睿识课（RSK）是北京睿识课科技有限公司打造的互联网安全科普平台。
            </p>
            <p>
              睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。
            </p>
            <a
              href="javascript:void(0)"
              @click="open()"
              class="goSafeBtn"
              >开启安全之旅</a
            >
          </div>
          <div class="SafetyBOX_right phonePage phone">
            <img src="@assets/images/mobile/img1.png" alt="" />
          </div>
          <div class="SafetyBOX_right hidden">
            <img src="@assets/images/mobile/img1.png" alt="" />
          </div>
        </div>
        <!--<p class="bgEDU">education</p>-->
      </figure>
    </section>
    <!--产品介绍-->
    <section class="grey clearfix product">
      <div style="position: relative; z-index: 1;">
        <img src="@assets/images/mobile/product.png" />
        <h4>产品介绍</h4>
        <div class="content">
          <p class="center">
            安全科普是大众所缺少且必须了解和掌握的重要知识，对于广大群众进行安全科普是提高他们素质和防范灾害能力，防止事故发生，保护安全生产的重要手段。
          </p>
          <p class="center">
            安全科普对减少伤亡事故来说，是最直接、最有效的措施。睿识课安全平台通过安全科普，可以促使广大群众在工作和生活中正确的按照客观规律办事，严格执行安全操作规程，加强对设备的维护检修，认识和掌握不安全、不卫生因素和伤亡事故规律，并正确运用科学技术知识加以治理和预防，及时发现和消除隐患，把事故消灭在萌芽状态，保证安全生产。
          </p>
        </div>
      </div>
    </section>
    <!--第一块结束-->
    <!--第二块-->
    <section class="SecondPage clearfix block2">
      <h3 class="titInfo phone">课程学习</h3>
      <div class="SecondPage_left">
        <img src="@assets/images/mobile/img2.png" alt="" />
        <div class="preView">
          <img class="line" src="@assets/images/mobile/line1.png" alt="" />
          <img class="view" src="@assets/images/mobile/view1.png" alt="" />
        </div>
      </div>
      <div class="SecondPage_right">
        <div class="title">
          <p class="one">
            <img src="@assets/images/mobile/smartphone.png" alt="" /><span
              >ONE</span
            >
          </p>
          <h3 class="titInfo">课程学习<i></i></h3>
        </div>
        <p class="studyInfo">
          睿识课教育平台为用户提供场景化、实用性强的安全科普课程，以预防在不同场所、不同环境下发生灾害。用户可以根据自己的实际情况选择课程组合，也可以自选课程。课程内容涵盖交通安全、医疗急救、灾害自救、防范暴力恐怖犯罪、日常意外处理等场景。课程形式以音频和视频为主，每节课以一个知识点为中心，在较短的时间内帮助用户掌握关键的安全知识，方便用户利用工作生活的碎片时间更高效地学习。从而不断增强用户的安全意识，提高自我保护能力。
        </p>
      </div>
    </section>
    <!--第二块结束-->
    <!--第三块-->
    <section class="SecondPage ThreePage hidden clearfix block3">
      <div class="SecondPage_right">
        <div class="title">
          <p class="one">
            <img src="@assets/images/mobile/box.png" alt="" /><span>TWO</span>
          </p>
          <h3 class="titInfo">安全测试<i></i></h3>
        </div>
        <p class="studyInfo">
          用户观看完系列课程后，系统会根据用户所看内容，随机从题库中抽取与本节课程相关的知识点，用户通过答题，加深对知识的理解和记忆，同时可以获得相对应的积分。
        </p>
      </div>
      <div class="SecondPage_left">
        <img src="@assets/images/mobile/img3.png" alt="" />
        <div class="preView">
          <img class="line" src="@assets/images/mobile/line2.png" alt="" />
          <img class="view" src="@assets/images/mobile/view2.png" alt="" />
        </div>
      </div>
    </section>
    <!--第三块结束-->
    <!--第三块手机-->
    <section class="SecondPage ThreePage phonePage clearfix block3">
      <h3 class="titInfo phone">安全测试</h3>
      <div class="SecondPage_left">
        <img src="@assets/images/mobile/img3.png" alt="" />
        <div class="preView">
          <img class="line" src="@assets/images/mobile/line2.png" alt="" />
          <img class="view" src="@assets/images/mobile/view2.png" alt="" />
        </div>
      </div>
      <div class="SecondPage_right">
        <p class="studyInfo">
          用户观看完系列课程后，系统会根据用户所看内容，随机从题库中抽取与本节课程相关的知识点，用户通过答题，加深对知识的理解和记忆，同时可以获得相对应的积分。
        </p>
      </div>
    </section>
    <!--第三块手机结束-->

    <!--left-->
    <section class="SecondPage clearfix block4">
      <h3 class="titInfo phone">安全服务</h3>
      <div class="SecondPage_left">
        <img src="@assets/images/mobile/img4.png" alt="" />
        <div class="preView">
          <img class="line" src="@assets/images/mobile/line3.png" alt="" />
          <img class="view" src="@assets/images/mobile/view3.png" alt="" />
        </div>
      </div>
      <div class="SecondPage_right">
        <div class="title">
          <p class="one">
            <img src="@assets/images/mobile/diamond.png" alt="" /><span
              >THREE</span
            >
          </p>
          <h3 class="titInfo">安全服务<i></i></h3>
        </div>
        <p class="studyInfo">
          经过用户授权，获取用户当前位置，为用户提供周边便捷服务，用户可享受一键报警、一键拖车、搜寻附近医疗、加油站、娱乐场所等多种服务。
        </p>
      </div>
    </section>
    <!--left end-->

    <!--第四块-->
    <section class="FourPage" style="display: none">
      <h4>安全系数图</h4>
      <ul class="FourImg_list">
        <li><img src="@assets/images/mobile/four1.png" alt="" /></li>
        <li><img src="@assets/images/mobile/four2.png" alt="" /></li>
      </ul>
    </section>
    <!--第四块结束-->
    <!--产品介绍-->
    <section class="grey clearfix means">
      <h4>产品目的与意义</h4>
      <i class="line"></i>
      <img src="@assets/images/mobile/stars.png" />
      <div class="content">
        <p class="center">
          安全科普是通过模拟生活中各种会发生事故的场景，以多种形式、不同角度来传输给用户一种规避风险、逃离灾难、保护自己的信息，努力提高人们的安全意识和素质，培养规避危险的潜意识，处理自己遇到的问题。安全科普主要是一种意识的培养，是长时期、甚至贯穿于人的一生的，对每个人都至关重要。缺乏安全意识极有可能导致在危急时刻错失救援时机，对生命安全造成重大伤害。
        </p>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  metaInfo: {
    title: "睿识课",
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是北京睿识课科技有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  methods: {
    open() {
      this.$root.$emit('openDialog', { type: 'dialog' })
    }
  }
};
</script>
<style lang="scss" scoped>
@charset "utf-8";
* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(
    0,
    0,
    0,
    0
  ); /*在移动设备上被触发点击事件时，响应的背景框的颜色。*/
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
  padding: 0px;
  margin: 0px;
  border: none;
}
html {
  height: 100%;
  font-size: 62.5%;
  -ms-text-size-adjust: none;
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
}
body {
  font-family: -apple-system, Helvetica, arial;
  color: #828282;
  min-width: 320px;
  margin: 0 auto;
  height: 100%;
  overflow-x: hidden;
  font-size: 12px;
}
li {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: #828282;
}
img {
  max-width: 100%;
  width: 100%;
  height: auto;
  border: none;
  image-rendering: optimizeQuality;
  -ms-interpolation-mode: bicubic;
}
.clear {
  clear: both;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
  clear: both;
}
.phone {
  display: none;
}
/*产品介绍*/
.grey {
  text-align: center;
  background: #f5f5f5;
  padding: 50px 0;
}
.product img {
  width: 60px;
  height: 56px;
}
.means > img {
  width: 82px;
  height: 10px;
  margin-bottom: 30px;
}
.means {
  padding-top: 70px;
}
.means .line {
  width: 26px;
  height: 2px;
  display: block;
  background: #d0e1f5;
  margin: 23px auto 30px auto;
}
.means {
  margin-top: 100px;
}
.grey h4 {
  margin-top: 3px;
  font-size: 26px;
  margin-bottom: 30px;
  color: #444;
  font-weight: 600;
  font-family: "宋体";
}
.grey .content {
  width: 960px;
  margin: 0 auto 20px auto;
  font-size: 18px;
  line-height: 34px;
  color: #444;
}

/*第一块*/
// .FirstPage{
//   position: relative;
// }
.bgblue {
  position: absolute;
  // z-index: -1;
  background: url(~@assets/images/mobile/BgBlue.png) no-repeat;
  background-size: cover;
  width: 100%;
  height: 764px;
  left: 0;
  right: 0;
}
.SafetyBOX {
  margin: 0 10.6% 0 14.7%;
  color: #fff;
  position: relative;
}
.SafetyBOX_left {
  float: left;
  width: 37.7%;
}
.SafetyBOX_left h3 {
  letter-spacing: 2px;
  font-size: 40px;
  font-weight: lighter;
  line-height: 56px;
  padding-top: 30px;
  margin-bottom: 20px;
  color: #fff;
}
.SafetyBOX_left p {
  line-height: 22px;
  font-size: 16px;
  margin-top: 5px;
  width: 380px;
}
.goSafeBtn {
  width: 156px;
  height: 33px;
  background: #fff;
  line-height: 33px;
  text-align: center;
  color: #4a9afa;
  display: block;
  border-radius: 4px;
  margin-left: 9%;
  margin-top: 49px;
  font-size: 13px;
}
.SafetyBOX_right {
  float: right;
  position: relative;
  z-index: 99;
  width: 45.91%;
}
// .SafetyBOX_right img {
/* width: 521px; */ /* height: 958px; */
// }
.bgEDU {
  font-size: 212px;
  font-family: Arial;
  color: rgba(70, 115, 170, 0.1);
  line-height: 244px;
  position: absolute;
  top: 669px;
  z-index: 9;
  text-transform: uppercase;
  margin-left: 3%;
}

/*第二块*/
.SecondPage {
  width: 81%;
  margin: 118px auto 0;
  height: 505px;
  overflow: hidden;
  border-bottom: 1px solid #f1f1f1;
}
.SecondPage_left,
.SecondPage_right {
  float: left;
}
.SecondPage_left img {
  width: 335px; /*height: 600px;*/
}
.SecondPage_right {
  margin-left: 13.3%;
  width: 45%;
  margin-top: 102px;
}
.title .one {
  position: relative;
}
.title .one img {
  width: 35px; /*height: 35px;*/
  vertical-align: middle;
  display: inline-block;
  margin-top: -68px;
}
.title .one span {
  margin-left: -10px;
  font-size: 100px;
  font-weight: 600;
  font-family: Arial;
  color: rgba(74, 154, 250, 0.1);
  line-height: 115px;
  text-transform: uppercase;
  display: inline-block;
}
.titInfo {
  color: #282828;
  font-size: 24px;
  margin-left: 7%;
  line-height: 33px;
  margin-top: -40px;
}
.titInfo i {
  display: block;
  width: 50px;
  height: 4px;
  background: rgba(74, 154, 250, 1);
  border-radius: 54px;
  margin-top: 10px;
}
.SecondPage_right .studyInfo {
  line-height: 27px;
  margin-top: 25px;
  font-size: 18px;
  font-weight: 400;
  margin-left: 8%;
}
/*第三块*/
.ThreePage {
  margin-top: 39px;
}
.ThreePage .SecondPage_right {
  margin-left: 13.3%;
  margin-right: 10.8%;
  margin-top: 95px;
  width: 38.5%;
}
.ThreePage .one img {
  position: absolute;
  left: 108px;
  top: 30px;
  margin-top: 0;
}
/*第四块*/
.FourPage {
  width: 81%;
  margin: 141px auto 254px;
}
.FourPage h4 {
  margin-left: 6.5%;
  font-size: 24px;
  line-height: 33px;
  color: #282828;
}
.FourImg_list {
  margin-left: 5%;
  margin-top: 67px;
}
.FourImg_list li {
  display: inline-block;
  width: 49%;
  text-align: center;
}
.FourImg_list li img {
  width: 297px; /*height: 283px;*/
}
/*foot*/
footer {
  background: #f9f9f9;
  padding: 54px 1% 53px 14.7%;
  width: 100%; /*margin-top:80px;*/
}
.footLeft img {
  width: 204px; /*height: 63px;*/
  transform: translateY(-25%);
}
.footLeft {
  float: left;
}
.footRight {
  margin-left: 15.6%;
}
.footRight ul li {
  display: inline-block;
  line-height: 28px;
  font-size: 20px;
  margin-bottom: 9px;
  margin-right: 8%;
}
.footRight ul li:last-child {
  margin-right: 0;
}
.footRight ul li a.on,
.footRight ul li a:hover {
  color: #282828;
}
.footRight p {
  font-size: 22px;
  line-height: 30px;
  color: #444;
}
.phonePage {
  display: none;
}
.qrcode {
  float: right;
  font-size: 18px;
  color: #444;
  line-height: 26px;
  width: 250px;
  margin-right: 30%;
}
.qrcode p {
  width: 145px;
  text-align: right;
  padding-right: 15px;
}
.qrcode p:nth-child(2) {
  margin-top: 30px;
}
.qrcode img {
  width: 110px;
  height: 110px;
  float: right;
}
/*线和预览*/
.SecondPage .preView {
  position: absolute;
  z-index: 999;
}
.block2 .preView {
  margin: -600px 0 0 310px;
}
.block2 .preView .line {
  width: 185px;
  height: 126px;
}
.block2 .preView .view {
  width: 270px;
  height: 166px;
  position: relative;
  bottom: 45px;
}

.block3 .preView {
  margin: -560px 0 0 -170px;
}
.block3 .preView .line {
  width: 206px;
  height: 82px;
}
.block3 .preView .view {
  width: 252px;
  height: 200px;
  position: relative;
  left: -270px;
  bottom: 68px;
}

.block4 .preView {
  margin: -300px 0 0 300px;
}
.block4 .preView .line {
  width: 196px;
  height: 89px;
}
.block4 .preView .view {
  width: 270px;
  height: 183px;
  position: relative;
  left: 0px;
  top: 48px;
}

@media only screen and (min-width: 1100px) {
  .bgblue {
    height: 850px;
    height: 56vw;
  }
}

@media only screen and (min-width: 950px) and (max-width: 1300px) {
  .ThreePage .SecondPage_right {
    margin-right: 4%;
  }
  .footRight {
    margin-left: 19%;
  }
  .SafetyBOX_left p {
    font-size: 1vw;
  }
}
@media only screen and (max-width: 950px) {
  .phone {
    display: block;
  }
  .pc {
    display: none !important;
  }
  .SecondPage .preView {
    position: relative;
    margin: 0;
    text-align: center;
    margin-top: -120px;
  }
  .SecondPage .preView .view {
    position: static;
    display: inline-block;
  }
  .preView .line {
    display: none;
  }
  .block2 .preView .view {
    width: 164px;
    height: 100px;
    transform: translate(50%, -220px);
  }
  .block3 .preView .view {
    width: 162px;
    height: 132px;
    transform: translate(-50%, -220px);
  }
  .block4 .preView .view {
    width: 176px;
    height: 123px;
    transform: translate(50%, -120px);
  }

  h3.titInfo {
    margin: 40px 0 17px 0;
    font-size: 23px;
    font-weight: 400;
  }
  .grey .content {
    width: 90%;
    font-size: 16px;
  }
  .SafetyBOX_left p {
    width: auto;
  }
  .NavLogo img {
    width: 90px; /*height:28px;*/
  }
  .NavLogo span {
    font-size: 16px;
    color: #fff;
    // transform: translateY(-85%);
    // margin-left: 5px;
    margin-left: 100px;
    margin-top: 25px;
  }
  .SecondPage {
    overflow: initial;
    border-bottom: none;
    height: auto;
    text-align: center;
    margin: 60px auto 0;
  }
  .SecondPage_left {
    float: none;
    height: auto;
    overflow: visibility;
  }
  .SecondPage_left > img {
    width: 83%;
  }
  .block2 .SecondPage_left > img,
  .block4 .SecondPage_left > img {
    transform: translateX(-20%);
  }
  .block3 .SecondPage_left > img {
    transform: translateX(20%);
  }
  .SecondPage_right {
    float: none;
    width: auto;
    margin: 0px auto 0;
    text-align: left;
  }
  .SecondPage_right .studyInfo {
    font-size: 14px;
    color: #444;
    margin-left: 0;
  }
  .SecondPage_right .title {
    display: none;
  }
  .ThreePage .SecondPage_right {
    margin: 50px auto;
  }
  .FourImg_list li {
    width: 100%;
    margin-bottom: 40px;
  }
  .FourPage {
    margin: 20px auto 0px;
  }
  .footLeft {
    float: none;
    margin-bottom: 0px;
  }
  .footRight {
    margin-left: 0;
    margin-top: -25px;
  }
  footer {
    padding: 14px 2% 33px;
    text-align: center;
  }
  .SafetyBOX {
    margin: 20px auto 40px;
  }
  .SafetyBOX_left {
    float: none;
    width: 75%;
    margin: 0 auto;
  }
  .SafetyBOX_left h3 {
    font-size: 28px;
    text-align: center;
    margin-bottom: 0px;
  }
  .SafetyBOX_right {
    width: 60%;
    margin: 0 auto;
    float: none;
  }
  .SafetyBOX_left p {
    color: #fff;
    font-size: 14px;
  }
  .hidden {
    display: none;
  }
  .phonePage {
    display: block;
  }
  .goSafeBtn {
    margin: 30px auto 10px; /*background: #4A9AFA;color: #fff;*/
  }
  .qrcode {
    display: block;
    float: none;
    margin: 15px auto 15px auto;
    width: 150px;
    font-size: 16px;
  }
  .qrcode img {
    float: none;
  }
  .qrcode p {
    text-align: center;
    width: 100%;
    padding: 0;
  }
  .qrcode p:nth-child(2) {
    margin-top: 5px;
  }

  .footLeft img {
    width: 81px;
  }
}
@media only screen and (max-width: 768px) {
  .SafetyBOX_right {
    width: 95%;
    margin: 0 auto;
    float: none;
  }
}
@media only screen and (max-width: 500px) {
  .footRight ul li {
    font-size: 14px;
    margin-right: 2%;
  }
  .footRight p {
    font-size: 16px;
  }
  .footLeft img {
    width: 81px;
  }
}
@media only screen and (max-width: 330px) {
  .SafetyBOX_left {
    float: none;
    width: 90%;
    margin: 0 auto;
  }
  .SafetyBOX_left p {
    margin-top: 10px;
  }
  .goSafeBtn {
    margin-top: 20px;
  }
}
</style>
