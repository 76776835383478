<template>
  <div class="setMeal-body" v-if="comboDetail.comboName">
    <a-row type="flex" justify="center">
      <a-col :xs="23" :md="9" :lg="7">
        <div class="left-content">
          <div class="left-info">
            <div class="cover">
              <div class="img">
                <img
                  v-if="comboDetail.picUrlList"
                  :src="comboDetail.picUrlList[0]"
                />
                <!-- <div
                  style="width: 258px; height: 144px; background-color: green"
                ></div> -->
              </div>
              <div class="count">
                <div class="triangle"></div>
                {{ comboDetail.paymentCount }}
              </div>
            </div>
            <h3>套餐介绍</h3>
            <div class="introduce">
              {{ comboDetail.describes }}
            </div>
            <div class="more">
              <!-- <a>更多</a> -->
            </div>
          </div>
        </div>
      </a-col>
      <a-col :xs="23" :md="14" :lg="16" :xl="17">
        <div class="right-content">
          <div class="nav-bar">
            <div class="nav">
              <div class="title">{{ comboDetail.comboName }}</div>
              <div class="information">
                <div class="logo">
                  <img :src="comboDetail.offerPicUrl" alt="" />
                </div>
                <div class="info name">{{ comboDetail.offerUserName }}</div>
                <div class="info time">{{ comboDetail.createUser }}</div>
              </div>
              <div class="nav-tag">
                <div class="tags" v-if="comboDetail.labelName">
              <span
                class="tag-item"
                v-for="(it, ind) in comboDetail.labelName.split('|')"
                :key="ind"
              >
                <a>#{{it.replace(/^\s+|\s+$/g,"")}}</a>
              </span>
                </div>
              </div>
            </div>
            <div class="button">
              <div class="button-btn play-btn" @click="turnPlayer('all')">
                <svg-icon icon-class="play" class-name="play-icon" />全部播放
              </div>
              <div class="button-btn xihuan-btn">
                <svg-icon icon-class="xihuan" class-name="xihuan-icon" />{{
                comboDetail.collectCount
                }}
              </div>
              <!-- <div class="button-btn shares-btn">
                <svg-icon icon-class="shares" class-name="sharess-icon" />分享
              </div> -->
            </div>
          </div>
          <div class="list">
            <div class="table-container">
              <table>
                <thead>
                <tr>
                  <th class="th-index" style="width: 10%;">{{ courseCount }}节</th>
                  <th class="th-course" style="width: 19%; padding-right: 1%;">课程</th>
                  <th class="th-courseName" style="width: 32%">课程名称</th>
                  <th class="th-publisher" style="width: 18%">类型</th>
                  <!-- <th class="th-pubTime" style="width: 14%">发布时间</th> -->
                  <th class="th-time" style="width: 10%">观看</th>
                </tr>
                <tr class="division">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <!-- <td></td> -->
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <tr
                  v-for="(item, index) in dataList"
                  :key="index"
                  :class="index % 2 === 0 ? 'even' : 'odd'"
                  @click="turnPlayer(item)"
                >
                  <td>
                    <div class="index-container">
                    <span class="em index">{{
                      index + 1 > 9 ? index + 1 : "0" + (index + 1)
                    }}</span>
                    </div>
                  </td>
                  <td>
                    <div class="course-container">
                      <div class="course em">
                        <a>
                          <img :src="item.picUrlList[0]" :alt="item.courseName" />
                        </a>
                        <div class="action">
                          <div class="player">
                            <div class="player-triangle"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="courseName-container">
                      <div class="course-name em">
                        <a>
                          <div class="title">{{ item.courseName }}</div>
                          <p class="text">
                            {{ item.describes }}
                          </p>
                        </a>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="publisher-container">
                      <div class="publisher">
                        <a>
                          <span>{{ item.categoryName }}</span>
                        </a>
                      </div>
                    </div>
                  </td>
                  <!-- <td>
                    <div class="pubTime-container">
                      <div class="pubTime">
                        <a>
                          <span>{{ item.offerUserName }}</span>
                        </a>
                      </div>
                    </div>
                  </td> -->
                  <td>
                    <div class="time-container ops-container">
                      <span class="time">{{ item.lookCount === 0 ? getRndInteger(100,300) : item.lookCount }} <img src="@assets/images/watch.png" alt="watch" /></span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
  <div class="setMeal-body" v-else></div>
</template>
<script>
import { comboDetail, comboCourseList } from "@api/setMeal/index";
export default {
  metaInfo: {
    title: "套餐 - 睿识课",
    meta: [
      {
        name: "keywords",
        content:"安全事故、安全科普、安全培训、消防培训、应急管理；驾驶安全、交通安全、车祸、车险、交通事故、自驾；诱拐、霸凌、亲子教育、儿童安全、儿童教育、性侵；火灾、洪灾、地震、火山、泥石流、着火、失火、发大水、山洪；医疗健康、急救、止血、骨折、伤残",
      },
      {
        name: "description",
        content:"睿识课（RSK）是北京睿识课科技有限公司打造的互联网安全科普平台。睿识课为安全而生，帮助用户增强安全意识，提高自我保护能力，为用户提供一系列安全科普知识，全方位的提高用户规避风险、防患于未然的安全意识。",
      },
    ],
  },
  data() {
    return {
      comboDetail: {},
      courseCount: 0,
      dataList: [],
    };
  },
  components: {},
  computed: {
    comboId: {
      get() {
        return this.$store.state.common.comboId;
      },
      set(val) {
        this.$store.commit("common/updateComboId", val);
      },
    },
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  activated() {
    this.comboId = window.sessionStorage.getItem('comboId')
    document.documentElement.scrollTop = document.body.scrollTop = 0;
    this.getDetail();
    this.getDataList();
  },
  methods: {
    getRndInteger(min, max) {
      return Math.floor(Math.random() * (max - min + 1) ) + min;
    },
    // 获取数据列表
    getDetail() {
      let params = { comboId: this.comboId };
      comboDetail(params).then(({ data }) => {
        if (data && data.success === true) {
          this.comboDetail = data.data;
        } else {
          this.comboDetail = {};
          console.log(data.message);
        }
      });
    },
    // 获取数据列表
    getDataList() {
      if (!this.comboId) {
        return false;
      }
      let params = { comboId: this.comboId };
      comboCourseList(params).then(({ data }) => {
        if (data && data.success === true) {
          this.courseCount = data.data.courseCount;
          this.dataList = data.data.electiveList;
          this.dataList = this.dataList.concat(data.data.requiredList);
        } else {
          this.dataList = [];
        }
      });
    },
    turnPlayer(data) {
      let courseId = null
      if(data === 'all'){
        courseId = this.dataList[0].courseId
        window.sessionStorage.setItem("courseList", JSON.stringify(this.dataList));
      }else{
        courseId = data.courseId
      }
      window.sessionStorage.setItem("Play", "combo");
      window.sessionStorage.setItem("comboId", this.comboDetail.comboId);
      window.sessionStorage.setItem("courseId", courseId);
      this.$router
        .push({
          name: "player",
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="scss" scoped>
.setMeal-body {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  padding-top: 15px;
  .left-content {
    .left-info {
      width: 274px;
      margin: 0 auto;
      .cover {
        position: relative;
        padding-right: 17px;
        &:before,
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          background-color: #d9d9d9;
          position: absolute;
          top: 0;
        }
        &:before {
          border: 0.5px solid #d6d6d6;
          right: 8px;
          transform: scale(0.85);
          transform-origin: 100% 50%;
          z-index: 2;
          border-radius: 2px;
        }
        &:after {
          border: 0.5px solid #c4c4c4;
          right: 0;
          transform: scale(0.73);
          transform-origin: 100% 50%;
          z-index: 1;
          border-radius: 2px;
          opacity: 0.5;
        }
        .img {
          position: relative;
          z-index: 3;
          border-radius: 2px;
          overflow: hidden;
          img {
            width: 100%;
            // height: 144px;
          }
        }
        .count {
          z-index: 4;
          position: absolute;
          right: 17px;
          top: 10px;
          padding-left: 15px;
          padding-right: 6px;
          background: #000;
          border-top-left-radius: 9px;
          border-bottom-left-radius: 9px;
          height: 17px;
          font-size: 12px;
          font-weight: 300;
          color: rgba(255, 255, 255, 1);
          line-height: 17px;
        }
        .triangle {
          position: absolute;
          left: 7px;
          top: 6px;
          width: 0;
          height: 0;
          border-top: 3px solid transparent;
          border-left: 6px solid #ffffff;
          border-bottom: 3px solid transparent;
        }
      }
      h3 {
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(74, 74, 74, 1);
        line-height: 16px;
        margin-top: 20px;
      }
      .introduce {
        font-size: 14px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: rgba(110, 110, 110, 1);
        line-height: 20px;
      }
      .more {
        margin-top: 30px;
        a {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(74, 154, 250, 1);
          line-height: 12px;
        }
      }
    }
  }

  .right-content {
    .nav-bar {
      .nav {
        color: $--title-color;
        .title {
          width: 100%;
          color: inherit;
          font-size: 24px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .information {
          display: flex;
          flex-direction: row;
          align-items: center;
          .logo {
            border-radius: 50%;
            overflow: hidden;
            margin-right: 5px;
            img {
              width: 32px;
              height: 32px;
            }
          }
          .info {
            font-size: 14px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: rgba(171, 171, 171, 1);
            line-height: 14px;
            margin-right: 10px;
          }
        }
        .nav-tag {
          margin-left: 42px;
          margin-bottom: 14px;
          .tags {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: rgba(74, 154, 250, 1);
            .tag-item {
              margin-right: 10px;
              a {
                color: inherit;
              }
            }
          }
        }
      }
      .button {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        .button-btn {
          width: 130px;
          height: 40px;
          border-radius: 20px;
          line-height: 40px;
          position: relative;
          // padding-left: 22px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(255, 255, 255, 1);
          cursor: pointer;
          margin-right: 14px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .play-btn {
          background: #4a9afa;
          .play-icon{
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
        }
        .xihuan-btn,
        .shares-btn {
          background-color: #f2f2f2;
          color: #6e6e6e;
          .xihuan-icon,
          .sharess-icon {
            // margin-left: 14px;
            margin-right: 4px;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
    .list {
      .table-container {
        height: 100%;
        overflow-y: auto;
        table {
          position: relative;
          width: 100%;
          table-layout: fixed;
          font-weight: 300;
          border-collapse: collapse;
          border-spacing: 0;
          thead {
            line-height: 50px;
            th {
              padding: 0 0px;
              text-align: left;
              font-weight: 300;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              color: #6e6e6e;
              padding: 0% 5px;
            }
            .th-index {
              text-align: center;
            }
            .th-time {
              text-align: center;
            }
            .division {
              border-top: 1px solid #ededed;
              height: 15px;
            }
          }
          tbody {
            tr {
              transition: background-color 0.2s linear;
              &.odd {
                background-color: #f7f7f7;
              }
              &.even:hover,
              .odd:hover {
                background-color: #e8e9ed;
              }
              td {
                padding: 3% 5px;
                vertical-align: top;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                .em {
                  color: #4a4a4a;
                  font-weight: 500;
                }
                .index-container {
                  display: flex;
                  justify-content: center;
                }
                .course-container {
                  .course {
                    position: relative;
                    a {
                      img {
                        width: 100%;
                        max-width: 150px;
                      }
                    }
                    .action {
                      max-width: 150px;
                      background: rgba($color: #000000, $alpha: 0.5);
                      position: absolute;
                      top: 0;
                      left: 0;
                      right: 0;
                      bottom: 0;
                      z-index: 3;
                      display: none;
                      justify-content: center;
                      align-items: center;
                      cursor: pointer;
                      .player {
                        float: right;
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        background: #4a9afa;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .player-triangle {
                          width: 0;
                          height: 0;
                          border-top: 6px solid transparent;
                          border-left: 10px solid #ffffff;
                          border-bottom: 6px solid transparent;
                          margin-left: 2px;
                        }
                      }
                    }
                    &:hover {
                      .action {
                        display: flex;
                      }
                    }
                  }
                }
                .courseName-container {
                  .course-name {
                    a {
                      .title {
                        height: 22px;
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: rgba(74, 74, 74, 1);
                        line-height: 22px;
                        margin-bottom: 10px;
                        word-wrap: break-word;
                        word-break: break-all;
                        text-overflow: ellipsis;
                        white-space: pre-wrap;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                      }
                      .text {
                        padding-right: 10px;
                        font-size: 14px;
                        font-family: PingFangSC-Light, PingFang SC;
                        font-weight: 300;
                        color: rgba(110, 110, 110, 1);
                        height: 56px;
                        line-height: 18px;
                        white-space: pre-wrap;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        /*! autoprefixer: ignore next */
                        -webkit-box-orient: vertical;
                      }
                    }
                  }
                }
                .time-container {
                  text-align: center;
                  white-space: pre-line;
                  .time {
                    font-family: PingFang SC;
                    img{
                      width: 18px;
                      height: 18px;
                      margin-left: 5px;
                    }
                  }
                }
                .course-name,
                .publisher,
                .pubTime {
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                }
                .publisher {
                  width: 100%;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-left: -2px;
                  a {
                    color: inherit;
                  }
                }
                .pubTime {
                  a {
                    color: inherit;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 1000px) {
  .setMeal-body {
    .right-content .list .table-container table tbody tr td .courseName-container .course-name a .text {
      -webkit-line-clamp: 2;
      height: 35px;
    }
  }
}
@media only screen and (max-width: 768px) {
  .setMeal-body {
    .left-content {
      .left-info {
        width: 100%;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .setMeal-body {
    .right-content .list .table-container table tbody tr td .courseName-container .course-name a {
      .title {
        height: 44px;
        -webkit-line-clamp: 2;
      }
      .text {
        display: none;
      }
    }
  }
}
</style>
