import Vue from 'vue'
import Antd from 'ant-design-vue/es'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import store from './store'
import '@/icons'
import '@/assets/scss/index.scss'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import { filterNull, getBrowser, formatAdList } from '@/utils'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import vuescroll from 'vuescroll'
import 'swiper/css/swiper.css'
import '@/utils/debugger'
// import Ripple from 'vue-ripple-directive'
//这是生成meta的
import MetaInfo from 'vue-meta-info'
// Ripple.color = 'rgba(242, 242, 242, 0.5)'; //自定义水波纹颜色

Vue.use(Antd)
Vue.use(MetaInfo)
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
Vue.use(vuescroll)
// Vue.directive('ripple', Ripple)

Vue.config.productionTip = false

// 挂载全局
Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.filterNull = filterNull     // 去除null
Vue.prototype.$getBrowser = getBrowser     // 判断是否为手机端
Vue.prototype.$formatAdList = formatAdList     // 格式化广告列表

window.Vue = new Vue({
  router,
  store,
  render: h => h(App),
  methods: {
  },
  //这个是重点 其他不用
  mounted () {
    document.dispatchEvent(new Event('render-event')) // 预渲染
  }
}).$mount('#app')
