/*setInterval(loop, 1)
function loop() {
  if (window.outerHeight - window.innerHeight > 200 || window.outerWidth - window.innerWidth > 200) {
    document.body.innerHTML = ""
    debugger
    window.stop()
    alert('检测到非法调试')
    window.location.reload()
  }
}*/


((function() {
  var callbacks = [],
    timeLimit = 50,
    open = false;
  setInterval(loop, 1);
  return {
    addListener: function(fn) {
      callbacks.push(fn);
    },
    cancleListenr: function(fn) {
      callbacks = callbacks.filter(function(v) {
        return v !== fn;
      });
    }
  }
  function loop() {
    var startTime = new Date();
    debugger;
    if (new Date() - startTime > timeLimit) {
      if (!open) {
        callbacks.forEach(function(fn) {
          fn.call(null);
        });
      }
      open = true;
      window.stop();
      alert('检测到非法调试');
      window.location.reload();
    } else {
      open = false;
    }
  }
})()).addListener(function() {
  window.location.reload();
});
