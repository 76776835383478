<template>
  <div id="app">
    <div class="content-body">
      <headers></headers>
      <div class="container">
        <keep-alive>
          <router-view></router-view>
        </keep-alive>
      </div>
      <footers></footers>
      <div v-if="maskVisible" class="mask-wrap"></div>
    </div>
  </div>
</template>

<script>
import headers from "@components/header";
import footers from "@components/footer";
export default {
  name: "App",
  components: {
    headers,
    footers
  },
  data() {
    return {
      maskVisible: false
    };
  },
  mounted () {
    this.$root.$on('visibleMask', this.visibleMask)
  },
  methods: {
    visibleMask (visible) {
      if (visible == this.maskVisible) return;
      this.maskVisible = visible
    }
  }
};
</script>

<style lang="scss" scoped>
html,
body,
#app {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.content-body {
  width: 100%;
  max-width: 1440px;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  background-color: #fff;
  .container {
    margin: 0 auto;
    padding-top: 70px;
  }
}
.mask-wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.45);
  transition: opacity 0.3s linear, height 0s ease 0.3s;
}
</style>
