<template>
  <header>
    <div class="nav-wrapper">
      <a class="nav-logo" href="javascript:void(0)" @click="turnIndex()">
        <img src="../assets/images/logo.png" alt="logo" />
      </a>
      <div class="nav-container">
        <div class="horizontal-nav-menus">
          <router-link
            v-for="(item,index) in menuList"
            @click.native="openDialog(item)"
            :to="item.link"
            :key="index"
            exact
          >{{item.name}}</router-link>
        </div>
        <div class="vertical-nav-menus">
          <a-button type="primary" @click="verticalNavOpen">
            <a-icon type="menu" />
          </a-button>
          <a-drawer
            placement="right"
            :closable="false"
            :visible="visible"
            @close="verticalNavClose"
            wrapClassName="vertical-nav-menus-dropdown"
          >
            <router-link
              v-for="(item,index) in menuList"
              @click.native="openDialog(item)"
              :to="item.link"
              :key="index"
              exact
            >
              <p>{{item.name}}</p>
            </router-link>
          </a-drawer>
        </div>
        <div class="nav-search">
          <input type="text" class="nav-search-input" v-model="searchValue" :placeholder="searchPlace" @focus="changeStatus" @blur="changeStatus" @keyup.enter="changeSearch" />
          <div class="nav-search-btn" @click="searchOrClear">
            <svg-icon :icon-class="searchIcon" class-name="nav-search-icon" />
          </div>
        </div>
      </div>
    </div>
    <download v-if="downloadVisible" ref="download"></download>
  </header>
</template>
<script>
import { hotSearchList } from "@api/discovery/index";
import download from "@/views/download";
export default {
  props: [
    'isMobile'
  ],
  data() {
    return {
      visible: false,
      downloadVisible: false,
      menuList: [
        { name: "发现", link: "/" },
        { name: "产品介绍", link: "/products" },
        { name: "关于我们", link: "/about" },
        { name: "客户端下载", link: "/download", type: "dialog" },
        { name: "API文档", link: "/apidoc" },
        { name: "机构端", link: "http://oss.rskjy.cn/#/login", type: "target" }
      ],
      searchIcon:'search',
      searchList: [],
      searchValue: '',
      searchPlace: "",
      repeatTime: null,
      collapsed: false
    };
  },
  components: {
    download
  },
  computed: {
    searchName: {
      get () { return this.$store.state.common.searchName },
      set (val) {
        this.$store.commit('common/updateSearchName', val)
      }
    }
  },
  mounted(){
    this.getSearchList()
    this.$root.$on('openDialog', this.openDialog)
  },
  methods: {
    // 获取搜索词语
    getSearchList() {
      hotSearchList().then(({ data }) => {
        if (data.data && data.success === true) {
          this.searchList = data.data
          this.searchPlace = this.searchList[0].content
          this.randomWord()
        } else {
          this.searchList = []
          console.log(data.message);
        }
      });
    },
    randomWord(){
      if(this.repeatTime === null){
        let num = 0
        let numLength = this.searchList.length
        this.repeatTime = setInterval(() => {
          let $that = this
          $that.searchPlace = $that.searchList[num].content
          if(num === numLength - 1){
            num = 0
          }else{
            num++
          }
        }, 1000 * 5);
      }
    },
    openDialog(_data) {
      if (_data.type) {
        if (_data.type === "dialog") {
          if (this.$getBrowser().versions.mobile) {
            window.location.href = 'https://ems-cnpl.rskjy.cn/download'
          } else {
            this.downloadVisible = true;
            this.$nextTick(() => {
              this.$refs.download.init();
            });
          }
        } else if (_data.type === "target") {
          window.open('http://oss.rskjy.cn/#/login')
        }
      }
    },
    changeStatus(){
      setTimeout(() => {
        this.searchIcon = this.searchIcon === 'close' ? 'search':'close'
      }, 200);
    },
    changeSearch(){
      if(this.searchValue === ''){
        this.searchValue = this.searchPlace
      }
      this.searchName = this.searchValue
      window.sessionStorage.setItem("searchName", this.searchValue);
      this.$router.push({
        name: 'search'
      }).catch(err=>err)
    },
    searchOrClear(){
      if(this.searchIcon === 'close'){
        this.searchValue = ''
      }else{
        this.changeSearch()
      }
    },
    turnIndex(){
      this.$router.push({
        name: 'discovery'
      }).catch(err=>err)
    },
    verticalNavOpen () {
      this.visible = true
    },
    verticalNavClose () {
      this.visible = false
    }
  }
};
</script>
<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 8px #f0f1f2;
  .nav-wrapper {
    height: 70px;
    max-width: 1230px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    overflow: hidden;

    .nav-logo {
      margin-right: 40px;
      img {
        width: 85px;
        height: 40px;
      }
    }

    .nav-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-grow: 1;
      .horizontal-nav-menus {
        display: flex;
        align-items: center;
        height: 100%;
        a {
          color: #4a4a4a;
          margin-right: 40px;
          font-size: 16px;
          font-weight: 300;
          &:hover {
            color: $--color-primary;
          }
          &.active {
            font-weight: 500;
            color: $--color-primary;
          }
        }
      }
      .vertical-nav-menus {
        display: none;
        .ant-dropdown-link {
          font-size: 24px;
        }
        .ant-dropdown-link.on {
          color: $--color-primary;
        }
      }
      .nav-search {
        height: 32px;
        display: flex;
        input {
          outline-color: invert;
          outline-style: none;
          outline-width: 0px;
          border: none;
          border-style: none;
          text-shadow: none;
          -webkit-appearance: none;
          -webkit-user-select: text;
          outline-color: transparent;
          box-shadow: none;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          font-weight: 300;
          color: #888888;
        }
        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          font-weight: 300;
          color: #888888;
        }
        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          font-weight: 300;
          color: #888888;
        }
        input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          font-weight: 300;
          color: #888888;
        }
        .nav-search-input{
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          border-bottom: 1px solid #4a4a4a;
          border-radius: 0;
          padding: 0;
          height: 100%;
          width: 113px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          color: #4a4a4a;
          font: 400 13.3333px Arial;
          line-height: 16px;
          transition: width 0.3s;
          &:focus{
            width: 250px;
            border-bottom: 3px solid #4a4a4a;
          }
        }
        .nav-search-btn {
          align-items: center;
          display: flex;
          .nav-search-icon {
            font-size: 22px;
            color: #4a4a4a;
            cursor: pointer;
          }
        }
      }
    }
  }
}
.vertical-nav-menus-dropdown {
  .ant-drawer-body {
    a {
      font-size: 16px;
      &:hover {
        color: $--color-primary;
      }
      &.active {
        font-weight: 500;
        color: $--color-primary;
      }
    }
  }
}

@media only screen and (max-width: 1230px) {
  header {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1020px) {
  header {
    .nav-wrapper {
      .nav-container {
        .nav-search {
          .nav-search-input {
            &:focus{
              width: 113px;
            }
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 890px) {
  header {
    .nav-wrapper {
      .nav-container {
        .horizontal-nav-menus {
          display: none;
        }
        .vertical-nav-menus {
          order: 1;
          display: block;
        }
        .nav-search {
          order: 0;
        }
      }
    }
  }
}
</style>
