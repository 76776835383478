<template>
  <div class="course-wrapper">
    <div v-if="!isLoading">
      <div class="play-btn" @click="turnPlayer('all')">
        <svg-icon icon-class="play" class-name="play-icon" />全部播放
      </div>
      <div class="list">
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th class="th-index" style="width: 8%">#</th>
                <th class="th-course" style="width: 26%">课程</th>
                <th class="th-publisher" style="width: 23%">发布者</th>
                <th class="th-pubTime" style="width: 23%">发布时间</th>
                <th class="th-time" style="width: 20%">观看量</th>
              </tr>
              <tr class="division">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody v-if="dataList && dataList.length > 0">
              <tr
                v-for="(item, index) in dataList"
                :key="index"
                :class="index % 2 === 0 ? 'odd' : 'even'"
                @click="turnPlayer(item)"
              >
                <td>
                  <div class="index-container">
                    <span class="em index">{{
                      index + 1 > 9 ? index + 1 : "0" + (index + 1)
                    }}</span>
                  </div>
                </td>
                <td>
                  <div class="course-container">
                    <div class="course-name em">
                      <a>
                        <span>{{ item.courseName }}</span>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="publisher-container">
                    <div class="publisher">
                      <a>
                        <span>{{ item.createUserName.indexOf('小程序') > -1 ? '睿识课' : item.createUserName}}</span>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="pubTime-container">
                    <div class="pubTime">
                      <a>
                        <span>{{ item.showonlineDate }}</span>
                      </a>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="time-container ops-container">
                    <span class="time"
                      >{{ item.virtualWatchCount }}
                      <img src="@assets/images/watch.png" alt="watch"
                    /></span>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr style="width: 100%; text-align: center">
                <td colspan="5">查询无结果</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page">
        <pagination
          v-if="pageVisiable"
          :current="page"
          :total="total"
          @change="getDataList"
        />
      </div>
    </div>
    <loading v-if="isLoading"></loading>
  </div>
</template>
<script>
import { courseList } from "@api/search/index";
import loading from "@/components/loading";
import pagination from "ant-design-vue/lib/pagination";
import "ant-design-vue/lib/pagination/style/css";
export default {
  data() {
    return {
      isLoading: true,
      total: 1,
      page: 1,
      limit: 10,
      dataList: [],
      pageVisiable: false,
    };
  },
  components: {
    loading,
    pagination,
  },
  computed: {
    searchName: {
      get() {
        return this.$store.state.common.searchName;
      },
    },
    comboId: {
      get() {
        return this.$store.state.common.comboId;
      },
      set(val) {
        this.$store.commit("common/updateComboId", val);
      },
    },
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  watch: {
    searchName(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.getDataList();
      }
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    // 获取数据列表
    getDataList(pageNumber) {
      if (pageNumber) {
        this.page = pageNumber;
      } else {
        this.page = 1;
      }
      let params = {
        name: this.searchName || window.sessionStorage.getItem("searchName"),
        page: this.page,
        limit: this.limit,
        queryAllCount: true,
      };
      this.isLoading = true;
      courseList(params).then(({ data }) => {
        if (data && data.success === true) {
          this.dataList = data.data.rows;
          this.total = data.data.total;
          if (this.total !== null) {
            this.pageVisiable = true;
          } else {
            this.pageVisiable = false;
          }
        } else {
          this.dataList = [];
          this.total = 0;
          this.pageVisiable = false;
        }
        this.isLoading = false;
      });
    },
    turnPlayer(data) {
      let courseId = null
      if(data === 'all'){
        courseId = this.dataList[0].courseId
        window.sessionStorage.setItem("courseList", JSON.stringify(this.dataList));
      }else{
        courseId = data.courseId
      }
      window.sessionStorage.setItem("Play", "course");
      window.sessionStorage.setItem("comboId", "");
      window.sessionStorage.setItem("courseId", courseId);
      this.$router
        .push({
          name: "player",
        })
        .catch((err) => err);
    },
  },
};
</script>
<style lang="scss" scoped>
.course-wrapper {
  max-width: 1230px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.play-btn {
  width: 130px;
  height: 40px;
  background: #4a9afa;
  border-radius: 20px;
  line-height: 40px;
  position: relative;
  padding-left: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  .play-icon{
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }
}
.list {
  .table-container {
    height: 100%;
    overflow-y: auto;
    table {
      position: relative;
      width: 100%;
      table-layout: fixed;
      font-weight: 300;
      border-collapse: collapse;
      border-spacing: 0;
      thead {
        line-height: 50px;
        th {
          padding: 0 5px;
          text-align: left;
          font-weight: 300;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: #6e6e6e;
        }
        .th-index {
          text-align: center;
        }
        .th-time {
          text-align: center;
        }
        .division {
          border-top: 1px solid #ededed;
          height: 15px;
        }
      }
      tbody {
        tr {
          height: 50px;
          line-height: 50px;
          transition: background-color 0.2s linear;
          &.odd {
            background-color: #f7f7f7;
          }
          &.even:hover,
          .odd:hover {
            background-color: #e8e9ed;
          }
          td {
            padding: 0 9px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .em {
              color: #4a4a4a;
              font-weight: 500;
            }
            .index-container {
              display: flex;
              justify-content: center;
            }
            .course-container {
              .course-name {
                a {
                  color: #4a9afa;
                }
              }
            }
            .time-container {
              text-align: center;
              white-space: pre-line;
              .time {
                img {
                  width: 20px;
                  height: 20px;
                }
              }
            }
            .course-name,
            .publisher,
            .pubTime {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            .publisher {
              width: 100%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: -2px;
              a {
                color: inherit;
              }
            }
            .pubTime {
              a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
.page {
  margin: 70px 0;
  text-align: right;
  /deep/.ant-pagination-item-link {
    color: #999999;
  }
  /deep/.ant-pagination-item {
    a {
      color: #999999;
    }
  }
  /deep/.ant-pagination-item-active {
    background-color: #4a9afa !important;
    a {
      color: #ffffff;
    }
  }
}
</style>
