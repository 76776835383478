import Vue from 'vue'
import VueRouter from 'vue-router'
import discovery from '../views/discovery/index'
import products from '../views/products/'
import about from '../views/about/'
import search from '../views/search/'
import setMeal from '../views/setMeal/'
import player from '../views/player'
import download from '../views/download'
import apidoc from '../views/apidoc'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'discovery',
    component: discovery
  },
  {
    path: '/products',
    name: 'products',
    component: products
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/setMeal',
    name: 'setMeal',
    component: setMeal
  },
  {
    path: '/player',
    name: 'player',
    component: player
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/download',
    name: 'download',
    component: download
  },
  {
    path: '/apidoc',
    name: 'apidoc',
    component: apidoc
  },
  {
    path: "*",
    component: discovery
  }
  // {
  //   path: '/certificate',
  //   name: 'cert',
  //   meta:{
  //     auth:true//添加字段判断该页面是否需要登录
  //   },
  //   component: Certificate
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active'
})
// 路由守卫
router.beforeEach((to,from,next)=>{
  // to要跳转到的路径
  // from从哪个路径来
  // next往下执行的回调
  // 在localStorage中获取token
  let token=window.sessionStorage.getItem('isShare')
  // 判断该页面是否需要登录
  if(to.meta.auth){
    // 如果token存在直接跳转
    if(token){
      next()
    }else{
      // 否则跳转到login登录页面
      // next({
      //     path:'/',
      //     // 跳转时传递参数到登录页面，以便登录后可以跳转到对应页面
      //     query:{
      //         redirect:to.fullPath
      //     }
      // })
    }
  }else if(to.name === 'download'){//阻止下载页跳转
    return false
  }else{
    // 如果不需要登录，则直接跳转到对应页面
    next()
  }
})
export default router
