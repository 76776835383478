<template>
  <div id="banner-container">
    <div v-swiper:mySwiper="swiperOption" ref="mySwiper" class="banner">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          :key="index"
          v-for="(banner, index) in banners"
          @click="turnDetail(banner)"
        >
          <div v-if="banner.isAdvert" class="adTag">广告</div>
          <img :src="banner.bannerPicUrl" class="banner-img" />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
    <div class="banner-button">
      <div class="button-btn banner-button-prev" @click="prev">
        <div class="button">
          <svg-icon icon-class="right" class-name="right-icon" />
        </div>
      </div>
      <div class="button-btn banner-button-next" @click="next">
        <div class="button">
          <svg-icon icon-class="right" class-name="right-icon" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* TODO 轮播接口加上广告信息字段 */
import { bannerList } from "@api/discovery/index";
export default {
  name: "banner",
  data() {
    return {
      banners: [],
      swiperOption: {
        initialSlide: 1,
        width: undefined, // 取消width，恢复自适应
        slidesPerView: 1,
        breakpoints: {
          920: {
            width: 1838,
            slidesOffsetBefore: -75,
            slidesPerView: 2
          }
        },
        centeredSlides: true,
        watchSlidesProgress: true,
        paginationClickable: true,
        pagination: {
          el: ".swiper-pagination",
          bulletClass: "my-bullet",
          bulletActiveClass: "my-bullet-active",
          clickable: false,
        },
        navigation: {
          nextEl: ".banner-button-next",
          prevEl: ".banner-button-prev",
        },
        // 监听
        observer: true,
        observeParents: true,
        observeSlideChildren: true,
        // 开启循环模式
        loop: true,
        loopedSlides: 8,
        loopAdditionalSlides: 3,
        // 自动轮播
        autoplay: {
          delay: 4000,
          //当用户滑动图片后继续自动轮播
          disableOnInteraction: false
        }
      }
    };
  },
  computed: {
    comboId: {
      get() {
        return this.$store.state.common.comboId;
      },
      set(val) {
        this.$store.commit("common/updateComboId", val);
      },
    },
    courseId: {
      get() {
        return this.$store.state.common.courseId;
      },
      set(val) {
        this.$store.commit("common/updateCourseId", val);
      },
    },
  },
  mounted() {
    this.getBannerList();
  },
  methods: {
    // 获取类型
    getBannerList() {
      let params = {
        showLocation: 2
      };
      bannerList(params).then(({ data }) => {
        if (data.data && data.success === true) {
          this.banners = data.data;
          /*this.banners.splice(1, 0, {
            isAdvert: 1,
            cover: this.banners[1].bannerPicUrl
          })*/
        } else {
          this.banners = [];
        }
      });
    },
    turnDetail(data) {
      if (data.isAdvert) {
        this.$root.$emit('openDialog', { type: 'dialog' })
        return
      }
      if (data.bannerType === 1) {
        window.sessionStorage.setItem("Play", "course");
        window.sessionStorage.setItem("comboId", "");
        window.sessionStorage.setItem("courseId", data.bannerContent);
        this.$router
          .push({
            name: "player",
          })
          .catch((err) => err);
      } else if (data.bannerType === 2) {
        this.comboId = data.bannerContent;
        this.courseId = "";
        window.sessionStorage.setItem("comboId", data.bannerContent);
        window.sessionStorage.setItem("courseId", '');
        this.$router
          .push({
            name: "setMeal",
          })
          .catch((err) => err);
      } else if (data.bannerType === 5) {
        window.open(data.bannerContent)
      }
    },
    prev () {
      this.mySwiper.slidePrev()
    },
    next () {
      this.mySwiper.slideNext()
    }
  },
};
</script>
<style lang="scss">
#banner-container {
  position: absolute;
  left: 0;
  top: 70px;
  width: 100%;
  .banner {
    width: 1440px;
    height: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    .swiper-wrapper {
      .swiper-slide {
        cursor: pointer;
        .adTag {
          position: absolute;
          right: 26px;
          top: 26px;
          background: rgba(153,153,153,0.7);
          border-radius: 4px;
          color: #fff;
          font-size: 18px;
          padding: 2px 10px;
        }
        .banner-img {
          width: 100%;
          max-height: 294px;
        }
      }
    }
    .swiper-pagination {
      padding-left: 19%;
      padding-bottom: 10px;
      .my-bullet {
        width: 7px;
        height: 7px;
        display: inline-block;
        border-radius: 50%;
        border: 1px solid #fff;
        margin: 0 7px;
      }
      .my-bullet-active {
        background: #ffffff;
        opacity: 1;
      }
    }
  }
  .banner-button {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 998;
    display: block;
    pointer-events: none;
    .button-btn {
      position: absolute;
      top: 40%;
      cursor: pointer;
      z-index: 999;
      pointer-events: auto;
      .button {
        width: 53px;
        height: 53px;
        background-color: #f2f2f2;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &:focus {
        outline: none;
      }
      &:nth-of-type(1) {
        left: 29%;
        .button {
          transform: rotate(180deg);
        }
      }
      &:nth-of-type(2) {
        right: 11%;
      }
    }
  }
  &:hover {
    .banner-button {
      display: block;
    }
  }
}
@media only screen and (max-width: 920px) {
  #banner-container {
    .banner {
      width: 100%;
      .swiper-pagination {
        padding-left: 0;
        padding-bottom: 0;
      }
    }
    .banner-button {
      .button-btn {
        .button {
          width: 8vw;
          height: 8vw;
          max-width: 53px;
          max-height: 53px;
        }
      }
      .button-btn:nth-of-type(1) {
        left: 3%;
      }
      .button-btn:nth-of-type(2) {
        right: 3%;
      }
    }
  }
}
</style>
